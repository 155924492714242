import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import Link from './Link';

class Menu extends React.PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isrequired,
        onClick: PropTypes.func
      })
    ).isRequired,
    small: PropTypes.bool,
    onClickedItem: PropTypes.func,
    rtl: PropTypes.bool,
    wide: PropTypes.bool
  }

  static defaultProps = {
    small: false,
    rtl: false
  }

  render () {
    return <ul className={css(
      styles.ul,
      this.props.rtl && styles.rtl,
      this.props.wide && styles.wide
    )}>
      {this.props.items.map(item =>
        <li key={item.label} className={css(styles.li)}>
          <Link menu small={this.props.small} onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            item.onClick(e)
            this.props.onClickedItem && this.props.onClickedItem(e)
          }}>{item.label}</Link>
        </li>
      )}
    </ul>
  }
}

const styles = StyleSheet.create({
  ul: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    width: 'auto'
  },
  wide: {
    width: '100%'
  },
  rtl: {
    textAlign: 'right'
  },
  li: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    lineHeight: 1.5
  }
})

export default Menu
