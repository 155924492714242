import React from 'react'
import PropTypes from 'prop-types'
import Ionicon from 'react-ionicons'
import { StyleSheet, css } from 'aphrodite';
import colors from '../colors';
import { Heading } from '../text';

class Row extends React.Component {
  static propTypes = {
    error: PropTypes.bool,
    children: PropTypes.node.isRequired,
    fixed: PropTypes.bool,
    isPressable: PropTypes.bool,
    spaced: PropTypes.bool,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    tertiary: PropTypes.bool,
    isCollapsible: PropTypes.bool,
    title: PropTypes.node,
    afterCollapsedContent: PropTypes.node,
    length: PropTypes.number
  }

  state = {
    isCollapsed: true
  }

  toggleIsCollapsed () {
    this.setState({isCollapsed: !this.state.isCollapsed})
  }

  render () {
    const {error} = this.props

    return <div className={css(
      styles.container,
      this.props.spaced && styles.spaced
    )}>
      <div
        className={css(
          styles.row,
          error && styles.rowError,
          this.props.fixed && styles.fixed,
          this.props.isPressable && styles.rowPressable,
          this.props.primary && styles.primary,
          this.props.secondary && styles.secondary,
          this.props.tertiary && styles.tertiary,
          (this.props.isCollapsible && this.state.isCollapsed) && styles.collapsed
        )
      }>
        {this.props.title && <div onClick={this.props.isCollapsible ? () => this.toggleIsCollapsed() : undefined}>
          <Heading>
            {this.props.title} <span style={{opacity: '0.4'}}>({this.props.length} notes)</span>
            {this.props.isCollapsible && <span style={{
              verticalAlign: 'middle'
            }}>
              {!!this.props.length && (this.state.isCollapsed
                ? <Ionicon icon='ios-arrow-forward' fontSize='14px' />
                : <Ionicon icon='ios-arrow-down' fontSize='14px' />)}
            </span>}
          </Heading>
        </div>}
        <div className={css(styles.rowInner)}>{this.props.children}</div>
      </div>
      {!!this.props.afterCollapsedContent && this.props.afterCollapsedContent}
    </div>
  }
}

const styles = StyleSheet.create({
  container: {
    // margin: '8px 16px'
  },
  row: {
    position: 'relative',
    overflow: 'hidden'
  },
  rowInner: {
    marginTop: '8px'
  },
  rowError: {
    backgroundColor: colors.coralLight
  },
  rowPressable: {
    backgroundColor: colors.greenLight
  },
  fixed: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0
  },
  spaced: {
    margin: '36px 0 0'
  },
  primary: {
    background: colors.greenBright
  },
  secondary: {
    background: colors.greyLight
  },
  tertiary: {
    background: colors.greenDim
  },
  collapsed: {
    height: '32px'
  }
})

export default Row
