import React from 'react'
import PropTypes from 'prop-types'
import { currentWeekstamp } from './DateFormat';
import BaseDetail from './BaseDetail';

class DayDetail extends React.PureComponent {
  static propTypes = {
    title: PropTypes.element.isRequired,
    notes: PropTypes.arrayOf(PropTypes.element),
    onClickAddNote: PropTypes.func.isRequired,
    addNotePathname: PropTypes.string.isRequired
  }

  render () {
    return <BaseDetail
      title={this.props.title}
      notes={this.props.notes}
      breadcrumbs={[{path: `/day-planner/${currentWeekstamp()}`, name: 'Planner'}]}
      addNotePathname={this.props.addNotePathname}
      onClickAddNote={this.props.onClickAddNote}
    />
  }
}

export default DayDetail
