import React from 'react'
import PropTypes from 'prop-types'
import Ionicon from 'react-ionicons'
import { StyleSheet, css } from 'aphrodite'
import colors from '../colors';

class IconButton extends React.PureComponent {
  static propTypes = {
    iconName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    size: PropTypes.number,
    text: PropTypes.string,
    highlight: PropTypes.bool,
    isPressed: PropTypes.bool,
    block: PropTypes.bool
  }

  static defaultProps = {
    size: 35,
    isPressed: false,
    highlight: false,
    block: false
  }

  render () {
    return <div
      className={css(
        styles.wrapper,
        this.props.highlight && styles.highlight,
        this.props.block && styles.block
      )}
      onClick={this.props.onClick}
    >
      <div className={css(styles.iconWrapper)}>
        <Ionicon
          className={css(styles.icon, this.props.isPressed && styles.pressed)}
          icon={`ios-${this.props.iconName}`}
          fontSize={this.props.size + 'px'}
          color={this.props.isPressed ? '#222' : this.props.highlight ? colors.greenDark : '#039179'}
        /> {this.props.text && <div className={css(
          styles.text,
          this.props.highlight && styles.textHighlighted
        )}>{this.props.text}</div>}
      </div>
    </div>
  }
}

const styles = StyleSheet.create({
  wrapper: {
    display: 'inline-block',
    verticalAlign: 'middle',
    cursor: 'pointer',
    textAlign: 'center',
    opacity: 0.6
  },
  block: {
    display: 'block',
    width: '100%',
    clear: 'both'
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    lineHeight: '28px'
  },
  icon: {
    cursor: 'pointer'
  },
  text: {
    display: 'inline-block',
    marginRight: '4px',
    fontSize: '16px',
    color: '#039179'
  },
  pressed: {
    border: '1px solid #c8c8c8',
    borderRadius: '3px',
    backgroundColor: '#f0f0f0',
  },
  highlight: {
    border: `1px solid ${colors.greenMuted}`,
    borderRadius: '3px',
    backgroundColor: colors.greenDim,
    boxShadow: '0 2px 3px -2px rgba(0,0,0,0.2)'
  },
  textHighlighted: {
    color: colors.greenDark,
    fontWeight: '500'
  }
})

export default IconButton
