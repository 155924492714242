import React from 'react'
import PropTypes from 'prop-types'
import uuidv4 from 'uuid/v4'
import { Switch, Route } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite';
import slugify from 'slugify'
import { BodyText, SectionTitle } from '../text';
import Note from '../chunks/Note';
import BrowserWithDetail from '../layout/BrowserWithDetail';
import BottomBar from '../chunks/BottomBar';
import { isMedia } from '../breakpoints';
import dbConnection from '../../store/dbConnection'
import { currentUser } from '../../store/authConnection'
import DayHeader from '../chunks/DayHeader';
import Link from '../controls/Link';
import { timestampToReadableTime, datestampToWeekstamp, currentWeekstamp, currentReadableDate, currentDatestamp, currentTimestamp } from '../chunks/DateFormat';
import LoadingMessage from '../chunks/LoadingMessage';
import NoteForm from '../chunks/NoteForm';
import { weekSummaryStyles } from './WeekSummary';
import colors from '../colors';
import breakpoints from '../breakpoints';

class TopicList extends React.PureComponent {
  render () {
    return <div className={css(weekSummaryStyles.container)}>
      {!!this.props.topics.length
        ? <div>
          <BodyText secondary>Select a topic to view notes.</BodyText>
          {this.props.topics.map(t => <div>
          	<Link to={`/topics/${slugify(t.name)}`}>{t.name + ' (' + t.noteCount + ' notes)'}</Link>
          </div>)}
        </div>
        : <div>
          <BodyText>You have not created any topics yet.</BodyText>
          <BodyText secondary>Create topics when you <Link to='/today'>write a note</Link></BodyText>
        </div>}
      </div>
  }
}

class TopicNoteList extends React.Component {
  static propTypes = {
    topic: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  state = {
  	isAddingNote: false,
  	isEditingNote: false
  }

  notes = []

  async componentDidMount () {
  	this.notesCollectionRef = dbConnection.collection('notes')
  		.where('userId', '==', currentUser().uid)
  		.where('topics', 'array-contains', this.props.topic.name)
  		.orderBy('timestamp', 'desc')

  	this.unsub = this.notesCollectionRef.onSnapshot(noteDocs => {
  	  this.notes = noteDocs.docs.map(n => n.data())
  	  this.forceUpdate()
  	}, console.error)
  }

  render () {
  	return <div className={css(styles.container)}>
  		<header className={css(styles.header)}>
  		  <DayHeader
  		    breadcrumbs={[{path: `/topics`, name: 'Topics'}]}
  		    title={<span>{this.props.topic.name}</span>}
  		    onClickAddNote={this.state.isAddingNote ? null : () => this.setState({isAddingNote: true})}
  		  />
  		</header>

  		{this.state.isAddingNote && <NoteForm
        alreadyExists={false}
        note={{
          id: uuidv4(),
          datestamp: currentDatestamp(),
          timestamp: currentTimestamp(),
          title: '',
          body: '',
          userId: currentUser().uid,
          topics: [this.props.topic.name]
        }}
        onFinish={note => {
          dbConnection.collection('notes').doc(note.id).set(note)
        	this.setState({isAddingNote: false})
          this.props.history.push(`/topics/${slugify(this.props.topic.name)}`)
        }}
        quickSave={note => dbConnection.collection('notes').doc(note.id).set(note)}
        onCancel={() => {
        	this.setState({isAddingNote: false})
        	this.props.history.push(`/topics/${slugify(this.props.topic.name)}`)
        }}
      />}

  		{this.notes.map(note => this.state.isEditingNote === note.id
  				? <NoteForm
            key={note.id}
            alreadyExists
            note={note}
            onFinish={note => {
              dbConnection.collection('notes').doc(note.id).set(note)
              this.setState({isEditingNote: false})
            }}
            quickSave={note => dbConnection.collection('notes').doc(note.id).set(note)}
            onCancel={() => this.setState({isEditingNote: false})}
          />
	  			: <Note
		        key={note.id}
		        title={note.title}
		        note={note.body}
		        topics={note.topics}
		        timestamp={note.timestamp}
		        isComplete={note.isComplete}
		        onClickTopic={slug => this.props.history.push(`/topics/${slug}`)}
		        onClickNote={() => this.setState({isEditingNote: note.id})}
		      />
		    )}
  	</div>
  }
}

class TopicSummary extends React.Component {
  static propTypes = {
    jumpToNote: PropTypes.func.isRequired,
    jumpToPlanner: PropTypes.func.isRequired,
    jumpToToday: PropTypes.func.isRequired,
    jumpToScratchPad: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
  }

  topics = []

  async componentDidMount () {
  	dbConnection.collection('topics')
  		.where('userId', '==', currentUser().uid)
  		.onSnapshot(topics => {
  			this.topics = topics.docs.map(d => d.data())
  			this.forceUpdate()
  		})
  }

  render () {
    return <div className={css(styles.container)}>
      <BrowserWithDetail
          title='Topics'
          bottomBar={isMedia('small', () => this.forceUpdate()) ? <BottomBar
            active='/day-planner'
            jumpToPlanner={this.props.jumpToPlanner}
            jumpToToday={this.props.jumpToToday}
            jumpToScratchPad={this.props.jumpToScratchPad}
          /> : null}
          detail={
            <Switch>
              <Route path='/topics/:topicSlug/add-note' render={({history, match}) => {

                return <div className={css(weekSummaryStyles.container)}>
                  <div>
                    <BodyText>There are no notes for this topic yet.</BodyText>
                    <Link
                      to={`/day-planner/${currentWeekstamp()}/${currentReadableDate()}/add-note?topic=${match.params.topicSlug}`}
                    >Add one now</Link>
                  </div>
                </div>
              }} />

              <Route path='/topics/:topicSlug' render={({history, match}) => {
                const topic = this.topics.find(t => match.params.topicSlug === slugify(t.name))

                if (!topic) {
                  return <div style={{padding: '40px 0', backgroundColor: colors.coralLight}}>
                    <SectionTitle center>Can't find this topic!</SectionTitle>
                    <BodyText center>Is this an error?</BodyText>
                    <Link to='/support'>Get help</Link>
                  </div>
                }

                return <TopicNoteList
                  history={history}
                  topic={topic}
                />
              }} />
              <Route path='/topics' render={() => <TopicList topics={this.topics} />} />
            </Switch>
          }
        />
    </div>
  }
}

export default TopicSummary

const styles = StyleSheet.create({
  container: {
    padding: '40px 36px',
    maxWidth: '800px',
    margin: '0 auto',
    [breakpoints.small]: {
      padding: '16px 4px'
    }
  },
  header: {
    marginTop: '40px',
    marginBottom: '24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  notes: {
    padding: '32px 0',
  }
})
