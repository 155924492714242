import React from 'react'
import {Prompt} from 'react-router-dom'
import PropTypes from 'prop-types'
import keyboardJS from 'keyboardjs'
import { StyleSheet, css } from 'aphrodite'
import TextArea from '../controls/TextArea'
import Button from '../controls/Button'
import TopicEditor from './TopicEditor'
import colors from '../colors'
import DayHeader from './DayHeader'
import DateFormat, { datestampToReadableDate, currentWeekstamp, datestampToWeekstamp, fullDay } from './DateFormat'
import IconButton from '../controls/IconButton';
import breakpoints from '../breakpoints';
import layers from '../layers';
import TitleEditor from './TitleEditor';

class NoteForm extends React.Component {
  static propTypes = {
    note: PropTypes.object.isRequired,
    onFinish: PropTypes.func.isRequired,
    quickSave: PropTypes.func,
    onCancel: PropTypes.func,
    isScratchPad: PropTypes.bool
  }

  state = {
    isExpanded: false,
    isSaving: false,
    isBlocking: false
  }

  confirmUnblock () {
    if (!this.state.isBlocking) {
      return true
    }

    keyboardJS.pause()
    const shouldUnblock = global.confirm('Abandon changes?')
    this.setState({isBlocking: shouldUnblock})
    setTimeout(() => keyboardJS.resume(), 3)
    return shouldUnblock
  }

  setDoneBlocking (cb) {
    this.setState({isBlocking: false}, cb)
  }

  setBlocking () {
    this.setState({isBlocking: true})
  }

  componentDidMount () {
    this.expandListener = e => this.toggleExpand()

    this.saveListener = this.save

    this.cancelListener = e => {
      if (!this.confirmUnblock()) {
        return false
      }
      e.preventRepeat()
      this.props.onCancel()
      // this.props.note.reset()
      keyboardJS.releaseAllKeys()
      keyboardJS.unbind('esc', this.cancelListener)
    }
    keyboardJS.watch()
    this.props.onCancel && keyboardJS.bind('esc', this.cancelListener)
    keyboardJS.bind('super + e', this.expandListener)
    keyboardJS.bind('super + s', this.saveListener)
    this.autoSaveInterval = setInterval(() => {
      if (this.props.alreadyExists && this.state.isBlocking && !!this.props.quickSave) {
        this.save({preventDefault: () => {}, stopPropagation: () => {}})
      }
    }, 10000)
  }

  componentWillUnmount () {
    this.props.onCancel && keyboardJS.unbind('esc', this.cancelListener)
    keyboardJS.unbind('super + e', this.expandListener)
    keyboardJS.unbind('super + s', this.saveListener)
    keyboardJS.stop()

    clearInterval(this.autoSaveInterval)

    if (this.state.isBlocking) {
      // There are unsaved changes that have been dismissed
      this.props.note.reset()
    }
  }

  save = async e => {
    this.setState({isSaving: true})
    e.preventDefault()
    e.stopPropagation()
    await this.props.quickSave(this.props.note)
    this.setDoneBlocking()
    this.setState({isSaving: false})
  }

  toggleExpand () {
    this.setState({isExpanded: !this.state.isExpanded})
    this.expandableTextArea.adjustHeight()
  }

  finish (e) {
    this.setDoneBlocking(() => this.props.onFinish(this.props.note))
  }

  render () {
  	if (!this.props.note.topics) {
  		this.props.note.topics = []
  	}

    return <div className={css(styles.container, this.state.isExpanded && styles.container__expanded)}>
      <form onSubmit={e => {
        e.preventDefault()
      }} className={css(
        styles.form,
        this.state.isExpanded && styles.formExpanded
      )}>
        <div className={css(styles.formInner)}>
          <div className={css(styles.datestamp)}>
            <DayHeader
              title={
                this.props.isScratchPad
                  ? <DateFormat datestamp={this.props.note.timestamp} format='time'/>
                  : this.props.alreadyExists
                    ? <DateFormat timestamp={this.props.note.timestamp} format='time' />
                    : <DateFormat datestamp={this.props.note.datestamp} format='fullDay' />
                }
              breadcrumbs={this.props.isScratchPad
                ? [
                  {path: `/day-planner/${currentWeekstamp()}`, name: 'Planner'},
                  {path: '/scratch-pad', name: 'Scratch Pad'}
                ]
                : this.props.alreadyExists
                  ? [{
                      path: `/day-planner/${datestampToWeekstamp(this.props.note.datestamp)}`, name: 'Planner'
                    }, {
                      path: `/day-planner/${datestampToWeekstamp(this.props.note.datestamp)}/${datestampToReadableDate(this.props.note.datestamp)}`,
                      name: fullDay(this.props.note.datestamp)
                    }]
                  : [
                    {path: `/day-planner/${datestampToWeekstamp(this.props.note.datestamp)}`, name: 'Planner'},
                  ]
              }
            />
          </div>
          <div className={css(styles.formControls, this.state.isExpanded && styles.formControlsExpanded)}>
            <IconButton isPressed={this.state.isExpanded} iconName={this.state.isExpanded ? 'contract' : 'expand'} onClick={() => this.toggleExpand()} size={28} />
          </div>
          <Prompt
            when={this.state.isBlocking}
            message='You have unsaved changes, are you sure you want to leave?'
          />

          {this.props.quickSave
            ? <Button small outline disabled={this.state.isSaving || !this.state.isBlocking} onClick={this.save}>{this.state.isSaving
              ? 'Saving…'
              : this.state.isBlocking ? 'Save' : 'Saved'
            }</Button>
            : null}

          <br />
          <br />

          <TextArea
            autofocus
            ref={ta => { this.expandableTextArea = ta }}
            placeholder='No notes yet'
            defaultValue={this.props.note.body}
            onChange={e => {
              this.setBlocking()
              this.props.note.body = e.target.value
            }}
            onCommandReturn={e => this.finish(e)}
            isExpanded={this.state.isExpanded}
          />

          <TitleEditor
            onUpdateTitle={newTitle => {
              this.setBlocking()
              this.props.note.title = newTitle
            }}
            defaultTitle={this.props.note.title}
          />

          <TopicEditor
            topics={this.props.note.topics}
            onAddTopic={topic => {
              this.setBlocking()
              this.props.note.topics.push(topic)
            }}
            onRemoveTopic={topic => {
              this.setBlocking()
              this.props.note.removeTopic(topic)
            }}
          />
          <div className={css(styles.footer)}>
            <Button small onClick={() => {
              this.confirmUnblock() && this.props.onCancel()
            }}>Cancel</Button>
            <Button onClick={e => this.finish(e)}>Done</Button>
          </div>
        </div>
      </form>
    </div>
  }
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    padding: '0 0 32px',
    backgroundColor: colors.white,
    [breakpoints.large]: {
      backgroundColor: colors.offWhite,
      display: 'flex',
      flex: 1
    }
  },
  container__expanded: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  form: {
    maxWidth: '800px',
    margin: '0 auto',
    position: 'relative',
    boxShadow: '0 2px 3px -2px rgba(0,0,0,0.2)',
    transition: 'all 0.2s',
    padding: '16px 12px',
    [breakpoints.large]: {
      backgroundColor: colors.white,
      borderRadius: '16px',
      width: '100%'
    }
  },
  formInner: {
    maxWidth: '700px',
    margin: '0 auto',
    [breakpoints.small]: {
      padding: '0 0 50px'
    }
  },
  datestamp: {
    marginBottom: '16px'
  },
  formExpanded: {
  	position: 'relative',
    width: '100%',
    borderRadius: 0,
    border: 'none',
    paddingTop: '8px',
    overflowY: 'scroll',
    backgroundColor: colors.white,
    maxWidth: 'none',
    zIndex: layers.overlay,
    [breakpoints.small]: {
      marginTop: '44px',
    },
    [breakpoints.large]: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  },
  formControls: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    transition: 'none',
    [breakpoints.small]: {
      display: 'none'
    }
  },
  formControlsExpanded: {
    transition: '0.3s ease-in-out',
    top: '40px',
    right: '40px'
  },
  footer: {
    textAlign: 'right',
    marginTop: '24px',
    [breakpoints.small]: {
      textAlign: 'center'
    }
  }
})

export default NoteForm
