import values from 'lodash.values'
import sortBy from 'lodash.sortby'
import isEmpty from 'lodash.isempty'
import { currentTimestamp, datestampToReadableDate } from "../components/chunks/DateFormat"
import { Object } from "es6-shim"

export default class DayStore {
  id
  userId
  created_at
  notes = {}
  datestamp

  dbConnection
  changeCallbacks = []

  constructor ({
    id,
    datestamp,
    created_at,
    notes = {},
    userId
  }, {dbConnection, getNote, uuid}) {
    if (!datestamp) {
      throw new Error('Tried to create a day without a datestamp.')
    }

    this.id = id || uuid()
    this.userId = userId
    this.created_at = created_at || currentTimestamp()
    this.datestamp = datestamp
    this.readableDate = datestampToReadableDate(datestamp)

    this.dbConnection = dbConnection

    this.unsub = dbConnection.collection('notes')
    	.where('userId', '==', userId)
    	.onSnapshot(notesSnapshot => {
    		this.notes = notesSnapshot.docs
    	})
  }

  getNote (noteId) {
  	const note = this.notes.find(noteDoc => noteDoc.id === noteId)
  	return note ? note.data() : undefined
  }

  get notesAsArray () {
    if (!Object.keys(this.notes).length) {
      return []
    }

    return Object.keys(this.notes).map(noteId => this.getNote(noteId))
  }

  reset () {
    this.notes = {}
    this.created_at = currentTimestamp()
  }

  snapshot () {
    return {
      id: this.id,
      userId: this.userId,
      datestamp: this.datestamp,
      created_at: this.created_at,
      readableDate: this.readableDate,
      notes: this.notes
    }
  }

  allNotes () {
    return sortBy(values(this.notes).map(n => this.getNote(n.id)), note => -note.timestamp)
  }

  isEmpty () {
    return isEmpty(this.notes)
  }

  save () {
    return this.dbConnection.collection('days').doc(this.id).set(this.snapshot())
  }

  setNote (note={}) {
    this.notes[note.id] = {id: note.id, created_at: note.created_at}
    note.dayId = this.id

    return note
  }

  removeNote (note) {
    delete this.notes[note.id]

    return this
  }

  hasFirstNote () {
    const len = Object.keys(this.notes).length
    const lenTruthy = Object.keys(this.notes).filter(key => !!this.notes[key]).length
    if (len !== lenTruthy) {
      console.error({wtf: lenTruthy, dayId: this.id})
    }

    return Object.keys(this.notes).length > 0
  }

  firstNote () {
    if (!this.hasFirstNote()) {
      throw new Error('called firstNote on empty set')
    }

    return this.getNote(Object.keys(this.notes)[0])
  }

  summaryTitle () {
    return this.hasFirstNote() ? this.firstNote().title : ''
  }

  summaryBody () {
    const note = this.hasFirstNote() ? this.firstNote().body.substring(0, 50) : ''

    return note.length === 50 ? note + '…' : note
  }

  notesNotSummarizedCount () {
    return this.notesAsArray.length - 1
  }

  toDaySummaryJSON (selectedDatestamp) {
    return {
      datestamp: this.datestamp,
      summaryTitle: this.summaryTitle(),
      summaryBody: this.summaryBody(),
      notesNotSummarizedCount: this.notesNotSummarizedCount(),
      isActive: selectedDatestamp === datestampToReadableDate(this.datestamp)
    }
  }

}
