import React from 'react'
import PropTypes from 'prop-types'
import { BodyText } from '../text';
import { StyleSheet, css } from 'aphrodite';
import Popover from './Popover';
import TextInput from '../controls/TextInput';
import Button from '../controls/Button';

class TitleEditor extends React.Component {
  static propTypes  = {
    defaultTitle: PropTypes.string,
    onUpdateTitle: PropTypes.func.isRequired
  }

  static defaultProps = {
    defaultTitle: ''
  }

  state = {
    newTitle: ''
  }

  updateTitle () {
    window.dispatchEvent(new MouseEvent('click'))
    this.setState({newTitle: ''})
    this.props.onUpdateTitle(this.state.newTitle)
  }

  render () {
    return <div className={css(styles.container)}>
      {!!this.props.defaultTitle && <BodyText inline>{this.props.defaultTitle}</BodyText>}
      <Popover inlineTrigger trigger={<Button hasFocus={false} small onClick={()=>{}}>
        {!!this.props.defaultTitle ? 'Change' : '+ Title'}
      </Button>}>
        <div>
          <TextInput
            autofocus
            defaultValue={this.props.defaultTitle}
            placeholder='No title yet'
            label='Title'
            onChange={e => this.setState({newTitle: e.target.value})}
            onReturn={() => this.updateTitle()}
          />
          <Button inline onClick={() => this.updateTitle()}>Save</Button>
        </div>
      </Popover>
    </div>
  }
}

const styles = StyleSheet.create({
  container: {
    margin: '8px 0 16px'
  }
})

export default TitleEditor
