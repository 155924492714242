import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import { withRouter } from 'react-router-dom'
import colors from '../colors'
import breakpoints, { isMedia } from '../breakpoints'

class TopBar extends React.PureComponent {
  static propTypes = {
    title: PropTypes.node,
    menuItems: PropTypes.arrayOf(PropTypes.element),
    menuOptions: PropTypes.arrayOf(PropTypes.object),
    pink: PropTypes.bool
  }

  render () {
    return <div className={css(styles.topBar, this.props.pink && styles.pinkBar)}>
      <div className={css(styles.title)}>{this.props.title && this.props.title}</div>
      <div className={css(styles.menu)}>{this.props.menuItems &&
        this.props.menuItems.map(l => <div key={l.key} className={css(styles.menuLink)}>{l}</div>)
      }</div>

      {(!!this.props.menuOptions && isMedia('handheld', () => this.forceUpdate())) && <select onChange={e => {
        this.props.history.push(e.target.value)
      }} className={css(styles.selectMenu)} name="menu" id="menu">
        {this.props.menuOptions.map(l => <option
          key={l.to}
          value={l.to}
          selected={l.to === this.props.location.pathname}
        >{l.name}</option>)}
      </select>}
    </div>
  }
}

const styles = StyleSheet.create({
  topBar: {
    backgroundColor: '#fbf7fa',
    borderBottom: '3px solid #f6f2f5',
    height: '70px',
    lineHeight: '70px',
    padding: '0 12px'
  },
  pinkBar: {
    backgroundColor: colors.pinkLight,
    borderBottom: '3px solid ' + colors.greenLight,
  },
  title: {
    float: 'left'
  },
  menu: {
    float: 'right',
    [breakpoints.handheld]: {
      display: 'none'
    }
  },
  menuLink: {
    display: 'inline-block',
    padding: '0 12px'
  },
  selectMenu: {
    display: 'inline-block'
  }
})

export default withRouter(TopBar)
