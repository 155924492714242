import BodyText from './BodyText'
import Heading from './Heading'
import PageTitle from './PageTitle'
import SectionTitle from './SectionTitle'
import UiLabel from './UiLabel'

export {
  BodyText,
  Heading,
  PageTitle,
  SectionTitle,
  UiLabel
}
