const breakpoints = {
  small: '@media (max-width: 820px)',
  handheld: '@media (max-width: 500px)',
  large: '@media (min-width: 821px)',
}

export const isMedia = (size, onChange) => {
  if (!window.matchMedia) {
    return
  }

  const mql = window.matchMedia(breakpoints[size].replace('@media ', ''))

  if (onChange) {
    mql.addListener(({matches}) => onChange(matches))
  }

  return mql.matches
}

export default breakpoints
