import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import Pill from './Pill'
import Button from '../controls/Button'
import Popover from './Popover'
import TextInput from '../controls/TextInput';

class TopicEditor extends React.PureComponent {
  static propTypes = {
    topics: PropTypes.array,
    onAddTopic: PropTypes.func.isRequired,
    onRemoveTopic: PropTypes.func.isRequired
  }

  static defaultProps = {
    topics: []
  }

  state = {
    newTopic: ''
  }

  addTopic () {
    window.dispatchEvent(new MouseEvent('click'))
    this.setState({newTopic: ''})
    this.state.newTopic.length && this.props.onAddTopic(this.state.newTopic)
  }

  render () {
    return <div className={css(styles.container)}>
      {this.props.topics.map(t => <Pill key={t} onRemove={removed => {
        this.props.onRemoveTopic(removed)
        this.forceUpdate()
      }}>{t}</Pill>)}
      <Popover inlineTrigger trigger={<Button hasFocus={false} small onClick={()=>{}}>+ Topic</Button>}>
        <div>
          <TextInput
            autofocus
            label='Topic'
            onChange={e => this.setState({newTopic: e.target.value})}
            onReturn={() => this.addTopic()}
          />
          <Button inline onClick={() => this.addTopic()}>Save</Button>
        </div>
      </Popover>
    </div>
  }
}

const styles = StyleSheet.create({
  container: {
    margin: '8px 0 16px'
  }
})

export default TopicEditor
