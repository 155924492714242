import React from 'react'
import { ViewPager, Frame, Track, View } from 'react-view-pager'
import { StyleSheet, css } from 'aphrodite'
import { spring } from 'react-motion'
import DateFormat, { currentWeekstamp, currentDatestamp, weekstampToDatestamp, datestampToWeekstamp } from '../chunks/DateFormat'
import DaySummary from '../chunks/DaySummary'
import IconButton from './IconButton'
import Button from './Button'
import LoaderOfNotes from '../dataContainers/LoaderOfNotes'
import colors from '../colors'
import breakpoints from '../breakpoints'


class DayPlannerWeekBrowser extends React.Component {
	state = {
		isDatePickerShowing: false
	}

  goToPage = index => {
    this.track.scrollTo(index)
  }

  _handleViewChange = async ([newIndex]) => {
    if (this.lastKnownPageIndex === newIndex) {
      // Already handled it
      return
    }

    setTimeout(() => {
	    (Math.abs(newIndex - this.lastKnownPageIndex) === 1 || !this.lastKnownPageIndex) &&
	    	this.props.onChangeInnerPage(newIndex)

	    this.lastKnownPageIndex = newIndex
      if (newIndex === 0) {
        this.props.onFirstPage()
      } else if (newIndex === this.props.weeks.length - 1) {
        this.props.onLastPage()
      }
    }, 30)
  }

  _getCurrentView (weekstamp) {
  	const weekstampIndex = this.props.weeks.indexOf(this.props.weeks.find(w => w.id === weekstamp))
  	if (this.lastWeekstamp === weekstamp) {
  		return this.lastWeekIndex
  	}

  	this.lastWeekstamp = weekstamp
  	this.lastWeekIndex = 2

  	if (weekstampIndex < 0) {
  		return 2
  	}

  	if (weekstampIndex === 0 || weekstampIndex === this.props.weeks.length - 1) {
  		return 2
  	}

  	this.lastWeekIndex = weekstampIndex

  	return weekstampIndex
  }

  _toggleDatePicker = console.log

  render () {
    const {
      weekstamp,
      weeks,
      onClickDay
    } = this.props

    return <div style={{
      marginBottom: '48px'
    }}>
      <div className={css(styles.browser)}>
      	<div className={css(styles.controls)}>
      	  <div className={css(styles.btn)}>
      	    <IconButton size={30} onClick={() => {
      	      if (!this.isSwiping) {
      	        this.isSwiping = true
      	        this.track.prev()
      	      }
      	    }} iconName='arrow-back' />
      	  </div>
      	  <div className={css(styles.datepickerButton)}>
      	    <Button small onClick={async () => this.props.handleClickTitle()}>
      	      <DateFormat format='week' datestamp={weekstampToDatestamp(this.props.weekstamp)} fallback='Unknown week' />
      	    </Button> <IconButton
      	      isPressed={this.state.isDatePickerShowing}
      	      size={20}
      	      onClick={this._toggleDatePicker}
      	      iconName={this.state.isDatePickerShowing ? 'close' : 'calendar'}
      	    />
      	  </div>
      	  <div className={css(styles.btn)}>
      	    <IconButton size={30} onClick={() => {
      	      if (!this.isSwiping) {
      	        this.isSwiping = true
      	        this.track.next()
      	      }
      	    }} iconName='arrow-forward' />
      	  </div>
      	</div>

      	{/*false && this.state.isDatePickerShowing && <div className={css(styles.datepicker)}>
      	  <CalendarMonth
      	    datestamp={this.props.month.datestamp}
      	    getNoteCountForDate={date => this.props.month.noteCounts[date]}
      	    onClickDay={datestamp => this.props.onClickDay(datestamp)}
      	    highestNoteCount={this.props.month.highestNoteCount()}
      	  />
      	</div>*/}

        {weeks.length === 0
          ? <div>Loading</div>
          : <ViewPager>
          <Frame>
            <Track
              ref={c => this.track = c}
              viewsToShow={1}
              instant
              currentView={this._getCurrentView(weekstamp)}
              onViewChange={this._handleViewChange}
              onRest={() => {
              	this.isSwiping = false
              }}
              onSwipeMove={() => {
              	if (!this.isSwiping) {
                	this.isSwiping = true
              	}
              }}
            >
              {weeks.map(({days, id}, i) => <View key={i + id}>
                <ul className={css(styles.weeksList)}>
                  {days.map((day, i) => <li key={`active-${i}`} className={css([this.props.selectedDatestamp === day.datestamp && styles.selectedWeeksListItem])}>
                    <DaySummary
                      key={day.datestamp}
                      datestamp={day.datestamp}
                      summaryTitle={day.summaryTitle}
                      summaryBody={day.summaryBody}
                      notesNotSummarizedCount={day.noteCount - 1}
                      onClick={datestamp => !this.isSwiping && onClickDay(datestamp)}
                    />
                  </li>)}
                </ul>
              </View>)}
            </Track>
          </Frame>
        </ViewPager>}
      </div>
    </div>
  }
}

export default class WeekList extends React.PureComponent {
	offset = 2
	state = {
		weekstampCursor: datestampToWeekstamp(this.props.initialSelectedDatestamp),
		fetchedCursor: undefined,
		selectedDatestamp: this.props.initialSelectedDatestamp
	}

	render () {
		return <div>
      <LoaderOfNotes
      	offset={this.offset}
      	weekstamp={this.state.weekstampCursor}
      	fetchedWeekstamp={this.state.fetchedCursor}
      >{weeks => <DayPlannerWeekBrowser
        ref={dp => this.pager = dp}
      	weeks={weeks}
      	selectedDatestamp={this.state.selectedDatestamp}
      	history={this.props.history}
      	weekstamp={this.state.weekstampCursor}
      	onClickDay={datestamp => {
          this.setState({selectedDatestamp: datestamp})
          this.props.onClickDay(datestamp)
        }}
      	handleClickTitle={() => {
      		this.pager.goToPage(2)
      		this.props.onClickDay(currentDatestamp())
      		this.setState({
      			selectedDatestamp: currentDatestamp(),
      			weekstampCursor: currentWeekstamp(),
      			fetchedCursor: currentWeekstamp()
      		})
      	}}
        onFirstPage={() => {
          this.setState({fetchedCursor: this.state.weekstampCursor}, () => () => this.pager.goToPage(2))
        }}
        onLastPage={() => {
          this.setState({fetchedCursor: this.state.weekstampCursor}, () => this.pager.goToPage(2))
        }}
        onChangeInnerPage={(newIndex) => {
          this.setState({weekstampCursor: weeks[newIndex].id})
        	this.props.onChangeWeek && this.props.onChangeWeek(weeks[newIndex].id)
        }}
    	/>}</LoaderOfNotes>
    </div>
	}
}

const styles = StyleSheet.create({
  dayContainer: {
    borderBottom: '1px solid #c8c8c8',
    padding: '16px 12px',
    minHeight: '70px',
    transition: 'background-color 200ms'
  },
  active: {
    backgroundColor: colors.greenLight,
    ':hover': {
      cursor: 'default',
      backgroundColor: colors.pinkLight,
    }
  },
  hasOnClick: {
    cursor: 'pointer',
    ':hover': {
      backgroundColor: colors.pinkUltraLight
    }
  },
  date: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px'
  },
  btn: {
    verticalAlign: 'middle',
    marginTop: '8px'
  },
  controls: {
    height: '48px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    verticalAlign: 'middle',
    borderBottom: '1px solid #c8c8c8',
    [breakpoints.small]: {
      position: 'relative',
      top: 0,
      width: '100%'
    }
  },
  datepickerButton: {
    lineHeight: '48px'
  },
  datepicker: {
    textAlign: 'center',
    margin: '8px 0',
    width: '100%'
  },
  title: {
    padding: '16px 12px',
    [breakpoints.small]: {
      marginTop: '48px'
    }
  },
  browser: {
    [breakpoints.small]: {
      paddingTop: '48px',
    }
  },
  weeksList: {
    overflowY: 'scroll',
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    userSelect: 'none'
  },
  selectedWeeksListItem: {
  	backgroundColor: colors.pinkLight
  }
})
