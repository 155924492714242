import React from 'react'
import { StyleSheet, css } from 'aphrodite'
import uuidv4 from 'uuid/v4'
import { Route, Switch } from 'react-router-dom'
import auth, { currentUser } from '../../store/authConnection'
import dbConnection from '../../store/dbConnection'
import DateFormat, { currentWeekstamp, readableDateToDatestamp, timestampToReadableTime, currentTimestampForDatestamp, fullDay } from '../chunks/DateFormat'
import DayHeader from '../chunks/DayHeader'
import Note from '../chunks/Note'
import NoteForm from '../chunks/NoteForm'
import Heading from '../text/Heading'
import IconButton from '../controls/IconButton'
import breakpoints from '../breakpoints'

export default class DayDetailScreen extends React.PureComponent {
  notes = []
  state = {
    editingNoteId: false,
    isAddingNote: false
  }

  constructor (props) {
    super(props)

    if ('/edit' === props.location.pathname.substring(props.location.pathname.length, props.location.pathname.length - 5)) {
      this.state.editingNoteId = notes => notes.find(n => {
        return timestampToReadableTime(n.timestamp) === props.match.params.time
      })
    }
  }

  componentDidMount () {
    this._setUpNotesListener(currentUser().uid)
    this.authStateUnsub = this._setUpAuthListener()
    this.props.match.params.addnote === 'add-note' && this.setState({isAddingNote: true})
  }

  componentWillUnmount () {
    this.authStateUnsub()
    this.unsub()
  }

  componentDidUpdate (lastProps) {
    if (lastProps.match.params.date !== this.props.match.params.date) {
      this._setUpNotesListener(currentUser().uid)
    }
  }

  onNotesChanged (notes) {
    this.notes = notes
    if (typeof this.state.editingNoteId === 'function') {
      this.setState({editingNoteId: this.state.editingNoteId(notes).id})
    }

    this.props.match.params.addnote === 'add-note' && this.setState({isAddingNote: true})

    this.forceUpdate()
  }

  render () {
    const {match, history} = this.props

    const datestamp = readableDateToDatestamp(match.params.date)

    return <div>
      <div className={css(styles.container)}>
        <header className={css(styles.header)}>
          {!!this.notes.length && <DayHeader
            breadcrumbs={[{path: `/day-planner/${currentWeekstamp()}`, name: 'Planner'}]}
            title={<DateFormat datestamp={datestamp} format='fullDay' />}
            onClickAddNote={() => history.push(`/day-planner/${match.params.weekstamp}/${match.params.date}/add-note`)}
          />}
        </header>
        <div className={css(styles.notes)}>

          <Switch>
            <Route
              key='add-note'
              exact path={`/day-planner/${match.params.weekstamp}/${match.params.date}/add-note`}
              render={({history}) => {
                const note = {
                  id: uuidv4(),
                  datestamp: datestamp,
                  timestamp: currentTimestampForDatestamp(datestamp),
                  title: '',
                  body: '',
                  userId: currentUser().uid
                }

                return <NoteForm
                  alreadyExists={false}
                  note={note}
                  onFinish={note => {
                    dbConnection.collection('notes').doc(note.id).set(note)
                    this.props.history.push(`/day-planner/${match.params.weekstamp}/${match.params.date}`)
                  }}
                  quickSave={note => dbConnection.collection('notes').doc(note.id).set(note)}
                  onCancel={() => history.push(
                    `/day-planner/${match.params.weekstamp}/${match.params.date}`
                  )}
                />
              }}
            />

            {this.notes.length === 0 && <div className='animated fadeIn slower' style={{
              width: '700px',
              maxWidth: '100%',
              margin: '5rem auto',
              textAlign: 'center'
            }}>
            	<Heading>{fullDay(readableDateToDatestamp(match.params.date))}</Heading>
              <Heading small>This day hasn't yet got any notes into it!</Heading>
              <div style={{marginTop: '16px'}}><IconButton
                size={28}
                text='Make the first one now!'
                iconName='add'
                onClick={() => history.push(`/day-planner/${match.params.weekstamp}/${match.params.date}/add-note`)}
              /></div>
            </div>}

            <Route
              key='note-or-form'
              path={`/day-planner/${match.params.weekstamp}/${match.params.date}`}
              render={() => this.notes.map(note => this.state.editingNoteId === note.id
                ? <NoteForm
                key={note.id}
                alreadyExists
                note={note}
                onFinish={note => {
                  dbConnection.collection('notes').doc(note.id).set(note)
                  this.props.history.push(`/day-planner/${match.params.weekstamp}/${match.params.date}`)
                  this.setState({editingNoteId: false})
                }}
                quickSave={note => dbConnection.collection('notes').doc(note.id).set(note)}
                onCancel={() => {
                  history.push(
                    `/day-planner/${match.params.weekstamp}/${match.params.date}`
                  )
                  this.setState({editingNoteId: false})
                }}
              />
              : <Note
                key={note.id}
                title={note.title}
                note={note.body}
                topics={note.topics}
                timestamp={note.timestamp}
                isComplete={note.isComplete}
                isShownInSummary={true}
                color={note.color}
                onClickDone={() => {}}
                toggleShowInSummary={() => {}}
                setPaperColor={() => {}}
                onClickNote={() => {
                  history.push(
                    `/day-planner/${match.params.weekstamp}/${match.params.date}/note/${timestampToReadableTime(note.timestamp)}/edit`)
                  this.setState({editingNoteId: note.id})
                }}
                onClickTopic={slug => history.push(`/topics/${slug}`)}
                handleCopy={() => {}}
                handleMove={() => {}}
                handleDelete={() => dbConnection.collection('notes').doc(note.id).delete()}
              />)}
            />
          </Switch>
        </div>
      </div>
    </div>
  }

  _setUpNotesListener (userId) {
    this.unsub && this.unsub()
    this.notes = []
    this.forceUpdate()

    const notesCollectionRef = dbConnection.collection('notes')
      .where('userId', '==', userId)
      .where('datestamp', '==', readableDateToDatestamp(this.props.match.params.date))
      .orderBy('timestamp', 'desc')

    this.unsub = notesCollectionRef.onSnapshot(noteSnapshots => {
      this.onNotesChanged(noteSnapshots.docs.map(d => d.data()))
    }, err => console.error(err))
  }

  _setUpAuthListener () {
    return auth.onAuthStateChanged(user => {
      if (user) {
        this._setUpNotesListener(user.uid)
        this.forceUpdate()
      } else {
        this.onNotesChanged([])
      }
    })
  }
}

const styles = StyleSheet.create({
  container: {
    padding: '40px 36px',
    maxWidth: '800px',
    margin: '0 auto',
    [breakpoints.small]: {
      padding: '16px 4px'
    }
  },
  header: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  notes: {
    padding: '32px 0',
  }
})
