import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import uuidv4 from 'uuid/v4'
import groupBy from 'lodash.groupby'
import { datestampToReadableDate, readableDateToDatestamp, currentTimestampForDatestamp, currentWeekstamp, currentDate, datestampToWeekstamp } from '../chunks/DateFormat'
import BrowserWithDetail from '../layout/BrowserWithDetail'
import { NoMatch } from '../../App'
import { currentUser } from '../../store/authConnection'
import Loader from '../chunks/Loader'
import { isMedia } from '../breakpoints'
import BottomBar from '../chunks/BottomBar'
import WeeksList from '../controls/WeeksList'
import DayDetailScreen from './DayDetailScreen'

class DayPlanner extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    defaultWeekstamp: PropTypes.string.isRequired,
    defaultDatestamp: PropTypes.number
  }

  notes = {}
  weeks = []

  drawer
  changeHandlerDisposer
  state = {
    drawerOpen: false,
    failedToLoadMessage: '',
    isLoading: false
  }

  ensureClosedDrawer () {
    this.drawer.close()
  }

  _jumpToToday = async shouldDismissDrawer => {
    shouldDismissDrawer && this.ensureClosedDrawer()
    this.props.history.push(
      `/day-planner/${currentWeekstamp()}/${currentDate()}`
    )
  }

  _jumpToPlanner = () => {
    this.ensureClosedDrawer()
    this.props.history.push('/')
  }

  _jumpToScratchPad = () => {
    this.ensureClosedDrawer()
    this.props.history.push('/scratch-pad')
  }

  _prepareNewNote = datestamp => {
    const note = {
      id: uuidv4(),
      datestamp: datestamp,
      timestamp: currentTimestampForDatestamp(datestamp),
      title: '',
      body: '',
      userId: currentUser().uid,
      topics: []
    }

    return note
  }

  setLoading () {
    this.setState({isLoading: true})
  }
  setDoneLoading () {
    this.setState({isLoading: false})
  }

  thisWeek (weeks) {
    const thisWeek = weeks.filter(({id}) => id === this.props.weekstamp)
    return thisWeek[0] ? thisWeek[0].days : []
  }

  // notes () {
  //   this.thisWeek(this.props.weeks).reduce((allNotes, day) => allNotes.concat(day.notes.filter(n => n.datestamp === currentDatestamp())), [])
  // }

  processNotes (weeks) {
    this.notes = groupBy(weeks.reduce(
      (allDays, {days, id}) => allDays.concat(
        days.reduce((allNotes, day) => allNotes.concat(day.notes.reduce((allNotes, note) => {
          return allNotes.concat({
            id: note.id,
            title: note.title,
            body: note.body,
            topics: note.topics,
            timestamp: note.timestamp,
            datestamp: note.datestamp,
            isComplete: note.isComplete,
            color: note.color
          })
        }, [])), []
      )), []
    ), 'datestamp')
  }

  render () {
    return <BrowserWithDetail
      title='Day Planner'
      ref={d => this.drawer = d}
      browser={
        <Route key='browser' path='/day-planner/:weekstamp?/:datestamp?' render={({match}) => {
          return <WeeksList
            onClickDay={datestamp => {
            	this.ensureClosedDrawer()
            	this.props.onClickDay(datestamp)
            }}
            initialSelectedDatestamp={readableDateToDatestamp(match.params.datestamp)}
            onChangeWeek={this.props.onChangeWeek}
          />
        }} />
      }
      bottomBar={isMedia('small', () => this.forceUpdate()) ? <BottomBar
        active='/day-planner'
        jumpToPlanner={this._jumpToPlanner}
        jumpToToday={this._jumpToToday}
        jumpToScratchPad={this._jumpToScratchPad}
      /> : null}
      detail={
        <div className='detailContainer'>
          {this.state.isLoading && <Loader />}

          <Switch>
            <Route
              key='day'
              path='/day-planner/:weekstamp/:date/:addnote?/:time?/:editnote?'
              component={DayDetailScreen}
            />
            <Route component={NoMatch} />
          </Switch>
        </div>
      }
    />
  }
}

export default DayPlanner
