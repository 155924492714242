import React from 'react'
import PropTypes from 'prop-types'
import groupBy from 'lodash.groupby'
import { weekstampToMoment, nextWeekstamp } from '../chunks/DateFormat'
import auth, { currentUser } from '../../store/authConnection'
import dbConnection from '../../store/dbConnection'

export default class LoaderOfNotes extends React.PureComponent {
  static propTypes = {
    children: PropTypes.func,
    update: PropTypes.func,
    weekstamp: PropTypes.string.isRequired,
    fetchedWeekstamp: PropTypes.string,
    offset: PropTypes.number.isRequired,
    onFetched: PropTypes.func
  }
  pagesData = []

  componentDidMount () {
  	this._setUpNotesListener(currentUser().uid)
    this.authStateUnsub = auth.onAuthStateChanged(user => {
      if (user) {
        this._setUpNotesListener(user.uid)
        this._updatePagesData(false)
        this.forceUpdate()
      } else {
        this.pagesData = []
      }
    })
  }

  componentWillUnmount () {
    this.authStateUnsub()
    this.unsub()
  }

  componentDidUpdate (lastProps) {
    if (this.props.fetchedWeekstamp !== lastProps.fetchedWeekstamp) {
      this._updatePagesData(false)
    }
  }

  _setUpNotesListener (userId) {
    this.unsub && this.unsub()
    const minimumDatestamp = weekstampToMoment(nextWeekstamp(this.props.weekstamp, -2)).startOf('w').valueOf()
    const maximumDatestamp = weekstampToMoment(nextWeekstamp(this.props.weekstamp, 2)).endOf('w').valueOf()

    const notesCollectionRef = dbConnection.collection('notes')
    	.where('userId', '==', userId)
    	.where('datestamp', '>', minimumDatestamp)
    	.where('datestamp', '<', maximumDatestamp)
    	.orderBy('datestamp', 'desc')

    this.unsub = notesCollectionRef.onSnapshot(noteSnapshots => {
      this.notes = noteSnapshots.docs.map(d => d.data())
      this._updatePagesData(false)
    }, err => console.error(err))
  }

  _updatePagesData (animated=true) {
    this.pagesData = this._listDays(groupBy(this.notes, 'datestamp'))
    this.forceUpdate()

    this.props.update && setImmediate(() => this.props.update(this.pagesData))
    this.props.onFetched && this.props.onFetched(this.props.weekstamp)
  }

  _listDays (notes) {
    const startOfPeriod = weekstampToMoment(this.props.weekstamp).subtract(this.props.offset, 'week').startOf('day')
    const weeks = [0, 1, 2, 3, 4].map(w => {
      return {
        id: nextWeekstamp(this.props.weekstamp, w - this.props.offset),
        days: [0, 1, 2, 3, 4, 5, 6].map(() => {
          const datestamp = Number(startOfPeriod)
          const day = {
            id: datestamp,
            datestamp: datestamp,
            summaryTitle: notes[datestamp] ? notes[datestamp][0].title : '',
            summaryBody: notes[datestamp] ? notes[datestamp][0].body : '',
            noteCount: notes[datestamp] ? notes[datestamp].length : 0,
            notes: notes[datestamp] ? notes[datestamp] : []
          }

          startOfPeriod.add(1, 'day').startOf('day')

          return day
        })
      }
    })

    return weeks
  }

  render () {
    return this.props.children ? this.props.children(this.pagesData) : null
	}
}
