import firebase from './firebase'

const db = firebase.firestore();

// Disable deprecated features
db.settings({
  timestampsInSnapshots: true
})

export default db
