import uuid from 'uuid/v4'
import sortBy from 'lodash.sortby'
import toArray from 'lodash.toarray'
import find from 'lodash.find'
import moment from 'moment'
import { monthstampToMoment, specificMonthstamp, monthstampToReadableDate, monthstampToDatestamp, monthstampToMonthNumber } from "../components/chunks/DateFormat";

export class MonthStore {
  number
  noteCounts = {}
  monthstamp
  datestamp
  id

  constructor ({id, number, monthstamp, datestamp, noteCounts={}, readableDate}) {
    if (!monthstamp) {
      throw new Error('Month needs monthstamp')
    }
    this.id = id ? id : uuid(process.env.REACT_APP_UUID_NAMESPACE)
    this.number = number || Number(monthstamp.split('-')[0])
    this.monthstamp = monthstamp
    this.noteCounts = noteCounts
    this.readableDate = readableDate
    this.datestamp = datestamp || monthstampToDatestamp(monthstamp)
  }

  highestNoteCount () {
    const counts = toArray(this.noteCounts).sort()
    return counts.length ? counts[0] : 0
  }
}

export class YearStore {
  months
  monthstamp
  changeHandlers = []
  disposers = []

  constructor ({monthstamp}, {dbConnection, userId}) {
    if (!monthstamp) {
      throw new Error('YearStore needs monthstamp')
    }
    this.months = {}
    this.monthstamp = monthstamp
    this.userId = userId
    this.dbConnection = dbConnection
    this.number = Number(monthstamp.split('-')[0])

    this.earliestMonth = monthstampToMoment(this.monthstamp).subtract(6, 'months')
    this.latestMonth = monthstampToMoment(this.monthstamp).add(5, 'months')

    this.collectionRef = this.dbConnection.collection('months')
      .where('userId', '==', this.userId)
      .where('datestamp', '>=', this.earliestMonth.valueOf())
      .where('datestamp', '<=', this.latestMonth.valueOf())

    this.disposers.push(this.collectionRef.onSnapshot(querySnapshot => querySnapshot.forEach(doc => {
      const month = doc.data()
      this.months[month.monthstamp] = new MonthStore(month)
      this.callChangeHandlers()
    })))
  }

  getMonthByMonthstamp (monthstamp) {
    return find(this.months, m => m.monthstamp === monthstamp)
  }

  sortedMonths () {
    return sortBy(toArray(this.months), m => m.datestamp)
  }

  onChange (cb) {
    const newLength = this.changeHandlers.push(cb)

    return () => this.changeHandlers.splice(newLength - 1, 1)
  }

  disposeListeners () {
    this.disposers.forEach(d => d())
  }

  callChangeHandlers () {
    this.changeHandlers.forEach(cb => cb(this))
  }

  async fetch () {
    if (!this.dbConnection) {
      throw new Error('Fetching with no dbConnection')
    }

    const monthDocs = await this.collectionRef.get()

    const c = moment(this.earliestMonth)
    const unfetched = [
      c.valueOf(),
      c.add(1, 'months').valueOf(),
      c.add(1, 'months').valueOf(),
      c.add(1, 'months').valueOf(),
      c.add(1, 'months').valueOf(),
      c.add(1, 'months').valueOf(),
      c.add(1, 'months').valueOf(),
      c.add(1, 'months').valueOf(),
      c.add(1, 'months').valueOf(),
      c.add(1, 'months').valueOf(),
      c.add(1, 'months').valueOf(),
      c.add(1, 'months').valueOf(),
    ]

    monthDocs.docs.forEach(doc => {
      const data = doc.data()
      const newMonth = new MonthStore(data)
      this.months[data.monthstamp] = newMonth
      unfetched.splice(unfetched.indexOf(monthstampToMoment(newMonth.monthstamp).valueOf()), 1)
    })

    unfetched.forEach(nonCreatedMonthstamp => {
      const monthstamp = specificMonthstamp(nonCreatedMonthstamp.valueOf())
      this.months[monthstamp] = new MonthStore({
        number: monthstampToMonthNumber(monthstamp),
        monthstamp,
        datestamp: monthstampToDatestamp(monthstamp),
        userId: this.userId,
        readableDate: monthstampToReadableDate(monthstamp)
      })
    })

    return this.months
  }
}
