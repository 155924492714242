import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const m = (d, f) => {
  return moment(d, f)
}

export const FORMATS = {
  WEEK_STAMP: 'ww-YYYY',
  ISO_WEEK_STAMP: 'WW-YYYY',
  DAY_NAME: 'dddd',
  DATE_STAMP: 'YYYY-MM-DD',
  TIME_STAMP: 'HH:mm:ss',
  READABLE_DATE: 'dddd, MMM D, YYYY',
  MONTH_STAMP: 'MM-YYYY',
  READABLE_WEEKSTAMP: 'MMM D'
}

export const currentMoment = () => moment()
export const currentTimestamp = () => moment().startOf('s').valueOf()
export const currentDatestamp = () => moment().startOf('d').valueOf()
export const currentReadableDate = () => specificReadableDate(currentDatestamp())
export const tomorrowDatestamp = () => moment().add(1, 'd').startOf('d').valueOf()
export const currentWeekstamp = () => moment().format(FORMATS.ISO_WEEK_STAMP)
export const currentMonthstamp = () => moment().format(FORMATS.MONTH_STAMP)
export const monthsFromTodayMoment = months => moment().startOf('month').add(months, 'months')

export const isToday = datestamp => m(datestamp).isSame(moment(), 'day')
export const isThisMonth = datestamp => m(datestamp).isSame(moment(), 'month')
export const isWithinWeek = (datestamp, weekstamp) => {
  return m(datestamp).isSame(weekstampToMoment(weekstamp), 'isoWeek')
}
export const isTheSameDay = (datestamp1, datestamp2) => {
  if (!datestamp1 || !datestamp2) {
    throw new Error('One of these is not a datestamp')
  }
  return m(datestamp1).isSame(m(datestamp2), 'd')
}
export const isMoreThanThreeDaysAgo = dateString => {
  return m(dateString).isBefore(m().subtract(3, 'd'), 'd')
}

export const dayOfWeek = datestamp => m(datestamp).format(FORMATS.DAY_NAME)
export const datestampForStartOfWeek = weekstamp => weekstampToMoment(weekstamp).startOf('isoWeek').valueOf()
export const datestampForEndOfWeek = weekstamp => weekstampToMoment(weekstamp).endOf('isoWeek').valueOf()
export const datestampToMoment = datestamp => m(datestamp)
export const monthstampToMoment = monthstamp => m(monthstamp, FORMATS.MONTH_STAMP)
export const monthstampToMonthNumber = monthstamp => Number(m(monthstamp, FORMATS.MONTH_STAMP).format('MM'))
export const monthstampToDatestamp = monthstamp => m(monthstamp, FORMATS.MONTH_STAMP).startOf('d').valueOf()
export const monthstampToReadableDate = monthstamp => m(monthstamp, FORMATS.MONTH_STAMP).format(FORMATS.DATE_STAMP)
export const weekstampToReadableWeek = weekstamp => 'Week of ' + weekstampToMoment(weekstamp).format(FORMATS.READABLE_WEEKSTAMP)
export const weekstampToMonthstamp = weekstamp => weekstampToMoment(weekstamp).format(FORMATS.MONTH_STAMP)
export const datestampToHumanDate = datestamp => m(datestamp).format(FORMATS.READABLE_DATE)
export const timestampToDatestamp = timestamp => m(timestamp).startOf('d').valueOf()

export const specificDatestamp = datestamp => m(datestamp).startOf('d').valueOf()
export const nextDatestamp = (datestamp, numDays=0) => {
  if (!datestamp) {
    datestamp = currentDatestamp()
  }
  return m(datestamp).add(numDays, 'd').startOf('d').valueOf()
}
export const specificReadableDate = datestamp => m(datestamp).format(FORMATS.DATE_STAMP)
export const specificWeekstamp = datestamp => m(datestamp).format(FORMATS.ISO_WEEK_STAMP)
export const specificMonthstamp = datestamp => m(datestamp).format(FORMATS.MONTH_STAMP)

export const datestampToWeekstamp = datestamp => m(datestamp).startOf('d').format(FORMATS.ISO_WEEK_STAMP)
export const weekstampToMoment = weekstamp => m(weekstamp, FORMATS.ISO_WEEK_STAMP)
export const weekstampToWeekNumber = weekstamp => Number(weekstampToMoment(weekstamp).format('ww'))
export const weekstampToDatestamp = weekstamp => weekstampToMoment(weekstamp).valueOf()

export const weekNumberFromWeekstamp = weekstamp => Number(weekstamp.split('-')[0])
export const nextWeekstamp = (weekstamp, numWeeks=1) =>
  weekstampToMoment(weekstamp).add(7 * numWeeks, 'days').endOf('week').format(FORMATS.ISO_WEEK_STAMP)
export const lastWeekstamp = (weekstamp=currentWeekstamp(), numWeeks=1) =>
  weekstampToMoment(weekstamp).subtract(7 * numWeeks, 'days').format(FORMATS.ISO_WEEK_STAMP)

export const currentTimestampForDatestamp = datestamp => {
  const mo = m(datestamp)
  const now = currentMoment()
  mo.set('hour', now.get('hour'))
  mo.set('minute', now.get('minute'))
  mo.set('second', now.get('second'))

  return mo.valueOf()
}

export const timestampFromReadableDateAndTime = (date, time) =>
  m(date + time, FORMATS.DATE_STAMP + FORMATS.TIME_STAMP).valueOf()

export const weekDatestampsFromWeekId = weekId => {
  if (!weekId) {
    throw new Error('Tried to call weekDatestampsFromWeekId with no weekId')
  }
  const dateIs = [0, 1, 2, 3, 4, 5, 6]
  return dateIs.map((d, i) => weekstampToMoment(weekId).startOf('d').add(i, 'd').valueOf())
}

export const week = datestamp => {
  const mStart = m(datestamp).startOf('isoWeek')
  const mEnd = m(mStart).add(6, 'd')
  const monthOne = mStart.format('MMM')
  const monthTwo = mEnd.format('MMM')

  return monthOne + ' ' +
    mStart.format('D') + '–' +
    (monthOne !== monthTwo
      ? monthTwo
      : '') +
    mEnd.format('D, YYYY')
}

export const dateToDatestamp = date => m(date).startOf('d').valueOf()

export const datestampToReadableDate = datestamp => {
  return m(Number(datestamp)).format(FORMATS.DATE_STAMP)
}
export const currentDate = () => datestampToReadableDate(currentDatestamp())
export const weekstampToReadableDate = weekstamp => {
  return weekstampToMoment(weekstamp).format(FORMATS.DATE_STAMP)
}
export const timestampToReadableTime = (timestamp, militaryTime=true) =>
  m(Number(timestamp)).format(militaryTime ? FORMATS.TIME_STAMP : 'LTS')
export const readableTimeToTimestamp = (readableTime) => {
	return m(readableTime, FORMATS.TIME_STAMP).valueOf()
}
export const readableDateToDatestamp = pathParam => {
  return m(pathParam, FORMATS.DATE_STAMP).valueOf()
}
export const day = datestamp => m(datestamp).format('MMM D, YYYY')
export const fullDay = datestamp => m(datestamp).format(FORMATS.READABLE_DATE)
export const fullDateAndTime = datestamp => m(datestamp).format(FORMATS.TIME_STAMP + ' ' + FORMATS.DATE_STAMP)

class DateFormat extends React.PureComponent {
  static propTypes = {
    datestamp: PropTypes.number,
    timestamp: PropTypes.number,
    format: PropTypes.oneOf(['week', 'day', 'dayOfWeek', 'fullDay', 'time', 'timeAndFullDay', 'month']),
    prefix: PropTypes.string,
    suffix: PropTypes.string
  }

  week () {
    return week(this.props.datestamp)
  }

  day () {
    return day(this.props.datestamp)
  }

  fullDay () {
    return fullDay(this.props.datestamp)
  }

  time () {
    return m(this.props.timestamp).format(FORMATS.TIME_STAMP)
  }

  month () {
    return m(this.props.datestamp).format('MMMM')
  }

  timeAndFullDay () {
    return m(this.props.timestamp).format(FORMATS.TIME_STAMP) + ', ' + m(this.props.datestamp).format(FORMATS.READABLE_DATE)
  }

  dayOfWeek () {
    return dayOfWeek(this.props.datestamp)
  }

  formatDate () {
    if (this.props.fallback && isNaN(this.props.datestamp) && isNaN(this.props.timestamp)) {
      return this.props.fallback
    }
    return this[this.props.format]()
  }

  render () {
    return <span>{this.props.prefix}{this.formatDate()}{this.props.suffix}</span>
  }
}

export default DateFormat
