import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import colors from '../colors';

class BodyText extends React.PureComponent {
  static propTypes = {
    children: PropTypes.string.isRequired,
    small: PropTypes.bool,
    center: PropTypes.bool,
    isAction: PropTypes.bool,
    spaced: PropTypes.bool,
    secondary: PropTypes.bool,
    inline: PropTypes.bool,
    pre: PropTypes.bool
  }

  render () {
    return <div className={css(
      styles.body,
      this.props.small && styles.small,
      this.props.center && styles.center,
      this.props.isAction && styles.action,
      this.props.spaced && styles.spaced,
      this.props.secondary && styles.secondary,
      this.props.inline && styles.inline,
      this.props.pre && styles.pre
    )}>
      {this.props.children}
    </div>
  }
}

const styles = StyleSheet.create({
  body: {
    color: '#415552',
    fontSize: '16px',
    lineHeight: '22px',
  },
  pre: {
    whiteSpace: 'pre-wrap'
  },
  small: {
    fontSize: '12px',
  },
  center: {
    textAlign: 'center'
  },
  action: {
    color: colors.green
  },
  spaced: {
    margin: '8px 0'
  },
  secondary: {
    opacity: 0.5
  },
  inline: {
    display: 'inline-block'
  }
})

export default BodyText
