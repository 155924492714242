import React from 'react'
import { PageTitle } from '../text'

class Loader extends React.PureComponent {
  render () {
    return <div style={{
      position: 'fixed',
      top: '70px',
      opacity: 0.5
    }}>
      <PageTitle small isLoading>Loading</PageTitle>
    </div>
  }
}

export default Loader
