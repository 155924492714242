import { currentTimestamp, currentDatestamp } from "../components/chunks/DateFormat"
import toArray from 'lodash.toarray'
import NoteStore from "./NoteStore";

class ScratchPadStore {
  id
  userId
  created_at
  updated_at
  notes = {}
  changeHandlers = []

  dbConnection
  constructor ({id, created_at, updated_at, notes, userId}, {dbConnection, getNote, uuid}) {
    this.id = id || uuid()
    this.userId = userId
    this.created_at = created_at || currentTimestamp()
    this.updated_at = updated_at || currentTimestamp()
    this.datestamp = currentDatestamp()

    this.dbConnection = dbConnection
    this.getNote = getNote

    this.notes = notes
  }

  onChange (cb) {
    const newLength = this.changeHandlers.push(cb)

    return () => this.changeHandlers.splice(newLength - 1, 1)
  }

  callChangeHandlers () {
    this.changeHandlers.forEach(cb => cb(this))
  }

  getSnapshot () {
    return {
      id: this.id,
      userId: this.userId,
      created_at: this.created_at,
      updated_at: this.updated_at,
      notes: this.notes
    }
  }

  allNotes () {
    return toArray(this.notes)
  }

  getScratchPadNote (id) {
    return new NoteStore(this.notes[id], {dbConnection: this.dbConnection, exists: true})
  }

  addNote (note={}) {
    note.dayId = 'scratchpad'
    note.userId = this.userId
    this.notes[note.id] = note.snapshot()

    this.callChangeHandlers()

    return note
  }

  async deleteNote (noteId) {
    delete this.notes[noteId]
    await this.save()
    this.callChangeHandlers()
  }

  save () {
    return this.dbConnection.collection('scratchpads').doc(this.id).set(this.getSnapshot())
  }
}

export default ScratchPadStore
