import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { StyleSheet, css } from 'aphrodite'
import DayHeader from './DayHeader'
import breakpoints from '../breakpoints'

class BaseDetail extends React.PureComponent {
  static propTypes = {
    title: PropTypes.element.isRequired,
    notes: PropTypes.arrayOf(PropTypes.element),
    breadcrumbs: PropTypes.arrayOf(PropTypes.object),
    addNotePathname: PropTypes.string.isRequired,
    onClickAddNote: PropTypes.func.isRequired,
  }

  render () {
    return <div>
      {this.props.notes.length
      ? <div className={css(styles.container)}>
        <header className={css(styles.header)}>
          {!!this.props.notes.length && <DayHeader
            breadcrumbs={this.props.breadcrumbs}
            title={this.props.title}
            onClickAddNote={this.props.onClickAddNote}
          />}
        </header>
        <div className={css(styles.notes)}>
          {this.props.notes}
        </div>
      </div>
      : <Redirect to={this.props.addNotePathname} />}
    </div>
  }
}

const styles = StyleSheet.create({
  container: {
    padding: '40px 36px',
    maxWidth: '800px',
    margin: '0 auto',
    [breakpoints.small]: {
      padding: '16px 4px'
    }
  },
  header: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  notes: {
    padding: '32px 0',
  }
})

export default BaseDetail
