import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import colors from '../colors';

class UiLabel extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    highlight: PropTypes.bool,
    large: PropTypes.bool,
    inline: PropTypes.bool,
    center: PropTypes.bool
  }

  static defaultProps = {
    highlight: false,
    large: false,
    inline: false
  }

  render () {
    return <div className={css(
      styles.uiLabel,
      this.props.highlight && styles.highlight,
      this.props.large && styles.large,
      this.props.inline && styles.inline,
      this.props.center && styles.center
    )}>
      {this.props.children}
    </div>
  }
}

const styles = StyleSheet.create({
  uiLabel: {
    color: '#8ea6a2',
    fontSize: '12px',
    lineHeight: '13px',
  },
  highlight: {
    color: colors.coralDark,
    fontWeight: '800'
  },
  large: {
    fontSize: '20px',
    lineHeight: '22px'
  },
  inline: {
    display: 'inline-block'
  },
  center: {
    textAlign: 'center'
  }
})

export default UiLabel
