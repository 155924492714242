import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import domAlign from 'dom-align'

class Popover extends React.Component {
  static propTypes = {
    trigger: PropTypes.element.isRequired,
    children: PropTypes.element.isRequired,
    side: PropTypes.string,
    inlineTrigger: PropTypes.bool,
    hoverable: PropTypes.bool
  }

  static defaultProps = {
    side: 'left',
    inlineTrigger: true
  }

  state = {
    active: false
  }

  componentDidMount () {
    window.addEventListener('click', () => this.state.active && this.toggleActive())
    this.props.hoverable && this.trigger.addEventListener('mouseover', () => !this.state.active && this.toggleActive())
  }

  toggleActive () {
    if (this.state.active) {
      this.popover && this.popover.setAttribute('class', this.popover.className.replace('fadeIn', 'fadeOut'))
      setTimeout(() => this.setState({active: false}), 300)
    } else {
      this.setState({active: true}, () => {
        setTimeout(() => domAlign(this.popover, this.trigger, {
          points: this.props.side === 'right'
            ? ['tr', 'tl']
            : ['tl', 'tr'],
          offset: [this.props.side === 'right' ? 30 : -30, 0]
        }), 10)
      })
    }
  }

  render () {
    return <div
      className={css(styles.container, this.props.inlineTrigger && styles.container__inline)}
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <div
        ref={trigger => this.trigger = trigger}
        className={css(styles.trigger)}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          this.toggleActive()
        }}
      >{this.props.trigger}</div>
      {this.state.active && <div
        ref={popover => this.popover = popover}
        className={css(styles.popover) + ' animated fadeIn faster'}
      >
        {this.props.children}
      </div>}
    </div>
  }
}

const styles = StyleSheet.create({
  container: {
    position: 'relative'
  },
  container__inline: {
    display: 'inline-block'
  },
  popover: {
    borderRadius: '16px',
    backgroundColor: '#fff',
    position: 'absolute',
    zIndex: '999',
    padding: '16px 12px',
    boxShadow: '0 4px 10px -3px rgba(0,0,0,0.22)',
    minWidth: '200px',
    maxWidth: '300px',
    top: '8px'
  },
  trigger: {
    position: 'relative',
    cursor: 'pointer',
    display: 'inline-block',
    margin: '0 8px',
  }
})

export default Popover
