import color from 'color'

export default {
  grey: '#203340',
  greyLight: '#E6EAED',
  greyDim: '#CCCED0',
  yellow: '#FFDE00',
  orange: '#FF644C',
  coral: '#E8391B',
  coralLight: '#ffe0da',
  coralDark: color('#ffe0da').darken(0.6).string(),
  pink: '#E81BC2',
  pinkLight: '#FEEFFF',
  pinkShaded: '#f6f2f5',
  pinkUltraLight: 'rgba(250, 243, 251, 0.5)',
  blue: '#74B3CE',
  greenLight: '#F7FFFC',
  green: 'rgba(52, 164, 145, 1)',
  greenBright: '#a6dfd7',
  greenDim: 'rgba(32, 144, 125, 0.2)',
  greenMuted: '#739293',
  greenMutedDark: (new color('#004346')).fade('0.4').toString(),
  greenDark: '#004346',
  white: '#FFFFFF',
  offWhite: 'rgb(252, 251, 252)'
}

export const heatMapColors = [
  'rgba(52, 164, 145, 0.6)',
  'rgba(52, 164, 145, 0.4)',
  'rgba(52, 164, 145, 0.3)',
  'rgba(52, 164, 145, 0.1)'
]

export const labelColors = [
  'yellow',
  'orange',
  'coral',
  'pink',
  'blue',
  'green'
]
