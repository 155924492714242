import React from 'react'
import PropTypes from 'prop-types'
import ExpandingTextarea from 'react-expanding-textarea'
import keyboardJS from 'keyboardjs'
import { StyleSheet, css } from 'aphrodite'
import colors from '../colors'
import { UiLabel } from '../text'

class TextArea extends React.PureComponent {
  static propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    onCommandReturn: PropTypes.func,
    autofocus: PropTypes.bool,
    isExpanded: PropTypes.bool,
    placeholder: PropTypes.string
  }

  static defaultProps = {
    placeholder: ''
  }

  adjustHeight () {
    setTimeout(() => this.expandingTextArea._adjustTextarea(this.expandingTextArea.el), 1)
  }

  componentDidMount () {
    this.listener = e => {
      e.preventRepeat()
      this.props.onCommandReturn && this.props.onCommandReturn(e)
      keyboardJS.releaseAllKeys()
      keyboardJS.unbind('super + enter', this.listener)
    }
    keyboardJS.watch(this.textarea)
    keyboardJS.bind('super + enter', this.listener)
    this.props.autofocus && this.expandingTextArea.el.focus()
  }

  componentWillUnmount () {
    keyboardJS.unbind('super + enter', this.listener)
    keyboardJS.stop()
  }

  render () {
    return <div className={css(styles.container)} ref={ta => this.textarea = ta}>
      {this.props.label && <label className={css(styles.label)} htmlFor={this.props.label}>
        <UiLabel>{this.props.label}</UiLabel>
      </label>}
      <ExpandingTextarea
        ref={eta => { this.expandingTextArea = eta }}
        placeholder={this.props.placeholder}
        className={css(styles.textarea, this.props.isExpanded && styles.expanded)}
        onChange={this.props.onChange}
        id={this.props.label ? this.props.label : ''}
        defaultValue={this.props.defaultValue}
        autoFocus={this.props.autofocus}
      />
    </div>
  }
}

const styles = StyleSheet.create({
  container: {
    borderBottom: '1px solid #c8c8c8',
    marginBottom: '8px'
  },
  label: {
    display: 'block',
    fontWeight: 'bold',
    padding: '0 0 4px',
  },
  textarea: {
    resize: 'none',
    color: '#415552',
    fontSize: '16px',
    lineHeight: '22px',
    border: 'none',
    width: '100%',
    display: 'block',
    minHeight: '60px',
    padding: '0 0 16px',
    ':focus': {
      outline: 'none',
      background: colors.offWhite,
      boxShadow: 'inset 0 0 4px 0 ' + colors.greyDim
    },
    '::placeholder': {
      opacity: 0.5
    }
  },
  expanded: {
    minHeight: '200px',
    fontSize: '20px',
    lineHeight: '30px',
    ':focus': {
      background: 'transparent',
      boxShadow: 'none'
    }
  }
})

export default TextArea
