import Promise from 'bluebird'
import uuidv5 from 'uuid/v5'
import sortBy from 'lodash.sortby'
import { weekDatestampsFromWeekId, weekstampToMoment, datestampToReadableDate, nextWeekstamp, isWithinWeek, currentWeekstamp, datestampToWeekstamp, currentMonthstamp, currentDatestamp, tomorrowDatestamp, lastWeekstamp, weekstampToMonthstamp } from "../components/chunks/DateFormat"
import DayStore from "./DayStore"
import NoteStore from './NoteStore'
import ScratchPadStore from './ScratchPadStore';
import { YearStore } from './MonthStore';

export class PermissionError extends Error {
  constructor(...params) {
    super(...params)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PermissionError)
    }

    this.code = 1
    this.date = new Date()
  }
}

export class NotFoundError extends Error {
  constructor(...params) {
    super(...params)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PermissionError)
    }

    this.code = 2
    this.date = new Date()
  }
}

export default class WeekStore {
  userId
  weekstamp
  dbConnection
  days = {}
  notes = []
  scratchPad
  thisWeekDays = []
  nextWeekDays = []
  lastWeekDays = []
  changeHandlers = []
  totalPages = 16
  fetchAtThreshold = 7
  lastFetchedAtWeekstamp = null
  pagesTurned = 0
  isFetching = false
  hasFetched = false
  fetchCount = 0

  constructor (weekstamp, userId, dbConnection) {
    this.userId = userId
    this.notes = []
    this.weekstamp = weekstamp
    this.monthstamp = weekstampToMonthstamp(weekstamp)
    this.lastFetchedAtWeekstamp = weekstamp
    this.dbConnection = dbConnection
  }

  getMonth (monthstamp) {
    return this.yearStore.getMonthByMonthstamp(monthstamp)
  }

  setUserId (userId) {
    if (!userId) {
      throw new Error('Did not provide userId')
    }

    this.userId = userId
  }

  get m () {
    return weekstampToMoment(this.lastFetchedAtWeekstamp)
  }

  needsToFetch (weekstamp) {
    return Math.abs(this.pagesTurned) >= this.fetchAtThreshold || this.weekstamps().indexOf(weekstamp) === -1
  }

  detectDirection (lastWeekstamp, nextWeekstamp) {
    const last = this.weekstamps()[lastWeekstamp]
    const next = this.weekstamps()[nextWeekstamp]

    return next > last ? 1 : -1
  }

  getNoteCountForDate () {
    return 5
  }

  async goToWeek (weekstamp, shouldFetch=true) {
    this.pagesTurned = this.pagesTurned + this.detectDirection(this.weekstamp, weekstamp)
    this.weekstamp = weekstamp
    this.monthstamp = weekstampToMonthstamp(weekstamp)
    return this.needsToFetch(weekstamp) && shouldFetch ? this.fetchCurrentSet() : Promise.resolve()
  }

  async goToToday () {
    return this.goToWeek(currentWeekstamp())
  }

  onChange (cb) {
    const newLength = this.changeHandlers.push(cb)

    return () => this.changeHandlers.splice(newLength - 1, 1)
  }

  callChangeHandlers () {
    this.changeHandlers.forEach(cb => cb(this))
  }

  earliestMoment () {
    return this.m.subtract((this.totalPages / 2) * 7, 'days').startOf('day').valueOf()
  }

  latestMoment () {
    return this.m.add((this.totalPages / 2) * 7, 'days').subtract(1, 'day').endOf('day').valueOf()
  }

  outerDatestamps () {
    return [this.earliestMoment(), this.latestMoment()]
  }

  weekstamps () {
    const weekstamps = []
    for (let i = 0; i < this.totalPages; i++) {
      weekstamps.push(nextWeekstamp(datestampToWeekstamp(this.earliestMoment()), i))
    }

    return weekstamps
  }

  weekIndexOfCurrentWeekstamp () {
    return this.weekstamps().indexOf(this.weekstamp)
  }

  weekIndexOfWeekstamp (weekstamp) {
    return this.weekstamps().indexOf(weekstamp)
  }

  indexToWeekstamp (index) {
    return this.weekstamps()[index]
  }

  async editNote (note, day) {
    this.notes[this.notes.indexOf(this.notes.find(n => n.id === note.id))] = note
    return this.saveAndApplyDayToNote(note, day)
  }

  async addNote (note, day) {
    note.userId = this.userId
    this.notes.push(note)
    return this.saveAndApplyDayToNote(note, day)
  }

  async saveAndApplyDayToNote (note, day) {
    // FIXME: Needs a transaction
    try {
      await note.save()
    } catch (err) {
      throw new Error('Failed to save note: ' + String(err))
    }

    day.setNote(note)

    try {
      await day.save()
    } catch (err) {
      throw new Error('Failed to save day: ' + String(err))
    }

    this.callChangeHandlers()
  }

  createDay ({datestamp, ...defaults}) {
    return new DayStore({datestamp, userId: this.userId, ...defaults}, {
      dbConnection: this.dbConnection,
      getNote: noteId => this.notes.find(n => n.id === noteId),
      uuid: () => uuidv5(String(this.userId + datestamp), process.env.REACT_APP_UUID_NAMESPACE)
    })
  }

  async deleteNote (note) {
    this.notes.splice(this.notes.indexOf(note), 1)

    // FIXME: this needs transaction
    this.days[note.dayId].removeNote(note).save()
    await note.delete()

    this.callChangeHandlers()
  }

  allDatestamps () {
    let datestamps = []

    this.weekstamps().forEach(weekstamp => {
      datestamps = datestamps.concat(weekDatestampsFromWeekId(weekstamp))
    })

    if (datestamps.length > 7 * this.totalPages) {
      throw new Error('weekDatestampsFromWeekId yielded > 7 * this.totalPages days')
    }

    return datestamps
  }

  getDayById (dayId) {
    return this.days[dayId]
  }

  getDay (datestamp) {
    if (!datestamp) {
      throw new Error('No datestamp provided to getDay')
    }

    for (const key in this.days) {
      if (datestampToReadableDate(this.days[key].datestamp) === datestampToReadableDate(datestamp)) {
        return this.days[key]
      }
    }

    return undefined
  }

  getNoteById (id) {
    return this.notes.find(note => note.id === id)
  }

  getNote (timestamp) {
    return this.notes.find(note => note.timestamp === timestamp)
  }

  setDay (day) {
    this.days[day.id] = day
  }

  updateNotesFromDbConnection (notes) {
    const notesList = notes.docs.map(n => {
      const note = new NoteStore(n.data(), {dbConnection: this.dbConnection, exists: true})
      note.onChange(() => this.callChangeHandlers())
      return note
    })
    this.notes = [...this.notes, ...notesList]
  }

  getDaysWithinWeek (weekstamp) {
    return sortBy(
      Object.keys(this.days)
        .filter(dayId => isWithinWeek(this.days[dayId].datestamp, weekstamp))
        .map(dayId => this.days[dayId]),
      'datestamp'
    )
  }

  setSelectedDatestamp (datestamp) {
    this.selectedDatestamp = datestamp
    this.callChangeHandlers()
  }

  weekNotes (weekstamp) {
    return this.getDaysWithinWeek(weekstamp)
      .map(day => day.allNotes())
      .reduce((allNotes, dayNotes) => allNotes.concat(dayNotes), [])
  }

  lastWeekNotes () {
    return this.weekNotes(lastWeekstamp())
  }

  todayNotes () {
    return this.getToday().allNotes()
  }

  getToday () {
    return this.getDay(currentDatestamp())
  }

  tomorrowNotes () {
    return this.getDay(tomorrowDatestamp()).allNotes()
  }

  scratchPadNotes () {
    return this.getScratchPad().allNotes()
  }

  getWeeks () {
    return this.weekstamps().map(weekstamp => this.getDaysWithinWeek(weekstamp))
  }

  getScratchPad () {
    return this.scratchPad
  }

  async fetchScratchPad () {
    const scratch = await this.dbConnection.collection('scratchpads')
      .where('userId', '==', this.userId)
      .get()

    const scratchPad = new ScratchPadStore(
      scratch.empty ? {userId: this.userId, notes: {}} : scratch.docs[0].data(),
      {
        dbConnection: this.dbConnection,
        uuid: () => uuidv5(String(this.userId + 'scratchpad'), process.env.REACT_APP_UUID_NAMESPACE)
      }
    )

    this.scratchPad = scratchPad

    return Promise.resolve(scratchPad)
  }

  async fetchCurrentYear () {
    this.yearStore = new YearStore({monthstamp: currentMonthstamp()}, {
      dbConnection: this.dbConnection,
      userId: this.userId
    })
    return this.yearStore.fetch()
  }

  async fetchCurrentSet () {
    if (!this.dbConnection) {
      throw new Error('Tried to WeekStore.fetchCurrentSet without a dbConnection')
    }

    if (!this.weekstamp) {
      throw new Error('Tried to WeekStore.fetchCurrentSet without a weekstamp')
    }

    this.isFetching = true

    this.lastFetchedAtWeekstamp = this.weekstamp

    const [earliestDatestamp, latestDatestamp] = this.outerDatestamps()

    // if the selected datestamp is outside the bounds of earliest? & latest?
    if (this.selectedDatestamp < earliestDatestamp || this.selectedDatestamp > latestDatestamp) {
      try {
        // Fetch the selected day...
        const selectedDay = await this.dbConnection.collection('days')
          .where('userId', '==', this.userId)
          .where('datestamp', '==', this.selectedDatestamp)
          .get()

        // ...or create it
        const day = this.createDay(selectedDay.empty
          ? {datestamp: this.selectedDatestamp}
          : selectedDay.docs[0].data())
        this.days[day.id] = day

        // Fetch the notes for the selected day
        const selectedDayNotes = await this.dbConnection.collection('notes')
          .where('userId', '==', this.userId)
          .where('dayId', '==', day.id)
          .get()
        this.updateNotesFromDbConnection(selectedDayNotes)
      } catch (err) {
        throw new Error('Failed fetch the currently selected day: ' + String(err))
      }
    }

    let dayDocs
    let noteDocs

    // Next, fetch all the days and notes within earliest to latest
    try {
      const {days, notes} = await Promise.props({
        days: this.dbConnection.collection('days')
          .where('userId', '==', this.userId)
          .where('datestamp', '>=', earliestDatestamp)
          .where('datestamp', '<=', latestDatestamp)
          .get(),
        notes: this.dbConnection.collection('notes')
          .where('userId', '==', this.userId)
          .where('timestamp', '>=', earliestDatestamp)
          .where('timestamp', '<=', latestDatestamp)
          .get()
      })

      dayDocs = days
      noteDocs = notes
    } catch (err) {
      if (err.code === 'permission-denied') {
        throw new PermissionError('Failed fetching days and notes.')
      } else if (err.code === 'invalid-argument') {
        throw new NotFoundError('Could not find this day\'s notes.')
      } else {
        throw new Error(err)
      }
    }

    const unfetched = this.allDatestamps()

    dayDocs.docs.forEach(doc => {
      const fetchedDay = this.createDay(doc.data())
      this.days[fetchedDay.id] = fetchedDay
      unfetched.splice(unfetched.indexOf(fetchedDay.datestamp), 1)
    })

    unfetched.forEach(nonCreatedDatestamp => {
      const newDay = this.createDay({datestamp: nonCreatedDatestamp})
      this.days[newDay.id] = newDay
    })

    this.updateNotesFromDbConnection(noteDocs)

    this.callChangeHandlers()

    this.isFetching = false
    this.hasFetched = true
    this.fetchCount++

    return this
  }
}
