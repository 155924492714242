import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import breakpoints from '../breakpoints'

// <TopBar title={<Link heavy muted to='/'>Week Notes</Link>} menuItems={[
  // <Link muted key='topbar-1' to={`/day-planner/${currentWeekstamp()}/${datestampToReadableDate(currentDatestamp())}`}>Day Planner</Link>,

class GetStarted extends React.PureComponent {
  render () {
    return <div className={css(styles.container)}>
      {!this.props.onlyDetails && <div>
        <div className={css(styles.textLarge)}>Made for people who think on the go</div>
        <div className={css(styles.textMedium)}>Week Notes is a platform for capturing, organizing and sharing thoughts and plans.</div>
      </div>}
      {!this.props.onlyHeading && <div>
        <div className={css(styles.textSmall)}><span style={{fontWeight: 'bold'}}>For writing.</span> Week Notes has you covered when you need to quickly jot something down, or when you need to focus in on some real fancy writing.</div>
        <div className={css(styles.textSmall)}><span style={{fontWeight: 'bold'}}>Perfect planning.</span> Pop in a simple shopping list, plan a day of errands, or work out a tricky project plan.</div>
        <div className={css(styles.textSmall)}><span style={{fontWeight: 'bold'}}>Clean and organized.</span> Simple tools help organize your notes intuitively so you can easily browse topics, or find a specific note.</div>
      </div>}
    </div>
  }
}

export default GetStarted

const styles = StyleSheet.create({
  textSmall: {
    fontSize: '17px',
    lineHeight: '24px',
    marginBottom: '24px'
  },
  textMedium: {
    fontSize: '32px',
    lineHeight: '46px',
    marginBottom: '40px',
    [breakpoints.small]: {
      fontSize: '20px',
      lineHeight: '26px'
    }
  },
  textLarge: {
    fontSize: '51px',
    lineHeight: '63px',
    fontWeight: 'bold',
    marginBottom: '16px',
    [breakpoints.small]: {
      fontSize: '30px',
      lineHeight: '36px'
    }
  }
})
