import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import colors from '../colors';

class Button extends React.PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    onClick: PropTypes.func,
    type: PropTypes.string,
    small: PropTypes.bool,
    inline: PropTypes.bool,
    text: PropTypes.bool,
    hasFocus: PropTypes.bool,
    block: PropTypes.bool,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    strong: PropTypes.bool,
    disabled: PropTypes.bool,
    outline: PropTypes.bool
  }

  static defaultProps = {
    small: false,
    inline: false,
    hasFocus: false,
    block: false,
    primary: false
  }

  componentDidUpdate () {
    this.props.hasFocus && this.button.focus()
  }

  render () {
    return <button ref={btn => this.button = btn} className={css(
      styles.button,
      this.props.small && styles.small,
      this.props.inline && styles.inline,
      this.props.text && styles.text,
      this.props.block && styles.block,
      this.props.strong && styles.strong,
      this.props.primary && styles.primary,
      this.props.secondary && styles.secondary,
      this.props.disabled && styles.disabled,
      this.props.outline && styles.outline
    )} disabled={this.props.disabled} onClick={this.props.onClick}>
      {this.props.children}
    </button>
  }
}

const styles = StyleSheet.create({
  button: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    color: '#039179',
    fontSize: '13px',
    lineHeight: '34px',
    fontWeight: '800',
    padding: '0 24px'
  },
  strong: {
    fontWeight: '700'
  },
  small: {
    fontWeight: '400',
    lineHeight: '22px',
    fontSize: '13px',
    padding: 0
  },
  inline: {
    display: 'inline-block'
  },
  text: {
    padding: 0
  },
  block: {
    display: 'block',
    width: '100%',
    textAlign: 'center'
  },
  primary: {
    backgroundColor: colors.green,
    height: '48px',
    lineHeight: '48px',
    borderRadius: '4px',
    color: colors.white
  },
  secondary: {
    color: colors.green,
    opacity: 0.5
  },
  outline: {
    borderWidth: '1px',
    borderColor: colors.green,
    borderStyle: 'solid',
    borderRadius: '0.1rem',
    padding: '2px 16px'
  },
  disabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
    borderColor: colors.greenMuted
  }
})

export default Button
