import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'

class SectionTitle extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    center: PropTypes.bool
  }

  render () {
    return <div className={css(styles.sectionTitle, this.props.center && styles.center)}>
      {this.props.children}
    </div>
  }
}

const styles = StyleSheet.create({
  sectionTitle: {
    color: '#415552',
    fontSize: '31px',
    lineHeight: '37px',
    fontWeight: '400'
  },
  center: {
    textAlign: 'center'
  }
})

export default SectionTitle
