import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import Drawer from 'rc-drawer'
import 'rc-drawer/assets/index.css'
import breakpoints, { isMedia } from '../breakpoints'
import colors from '../colors'
import authConnection from '../../store/authConnection'
import IconButton from '../controls/IconButton'
import layers from '../layers';
import { PageTitle } from '../text';
import Link from '../controls/Link';
import { currentWeekstamp } from '../chunks/DateFormat';
import Row from '../chunks/Row';

export default class BrowserWithDetail extends React.Component {
  static propTypes = {
    browser: PropTypes.element,
    title: PropTypes.node,
    bottomBar: PropTypes.array,
    detail: PropTypes.element
  }

  state = {
    drawerOpen: false,
    menuOpen: false
  }

  componentDidMount () {
    let latestKnownScrollY = 0
    let ticking = false

    const requestTick = () => {
      if (!ticking) {
        requestAnimationFrame(update)
      }
      ticking = true
    }

    const onScroll = () => {
      latestKnownScrollY = window.scrollY
      requestTick()
    }

    const update = () => {
      if (isMedia('handheld', () => this.forceUpdate())) {
        return
      }

      ticking = false
      const currentScrollY = latestKnownScrollY
      this.browserEl && this.browserEl.setAttribute('style', `top: ${currentScrollY > 70 ? '0' : 70 - currentScrollY}px !important;`)
    }

    window.addEventListener('scroll', onScroll, false)
  }

  toggleDrawerOpen () {
    this.setState({drawerOpen: !this.state.drawerOpen})
  }

  toggleMenuOpen () {
    this.setState({menuOpen: !this.state.menuOpen})
  }

  close () {
    this.setState({drawerOpen: false})
  }

  render () {
    return <div className={css(styles.container)}>

      {isMedia('small', () => this.forceUpdate()) && <div className={css(
        styles.bottomBar,
        this.state.drawerOpen && styles.bottomBarDrawerOpen
      )}>
        {this.props.bottomBar}
      </div>}

      {isMedia('small', () => this.forceUpdate()) && <div className={css(styles.header)}>
        <IconButton
          iconName={this.state.menuOpen ? 'close' : 'menu'}
          onClick={() => this.toggleMenuOpen()} size={44}
        />
        <PageTitle small tall>{this.props.title}</PageTitle>
        {!!this.props.browser ? <IconButton
          iconName={this.state.drawerOpen ? 'close' : isMedia('small', () => this.forceUpdate()) ? 'calendar-outline' : 'arrow-back'}
          onClick={() => this.toggleDrawerOpen()} size={44}
        /> : <div></div>}
      </div>}

      <div className={css(styles.detailContainer)}>
        {isMedia('small', () => this.forceUpdate()) && <div>
          {!!this.props.browser && <Drawer
            width={isMedia('handheld') ? '80%' : '300px'}
            open={this.state.drawerOpen}
            handler={false}
            onMaskClick={() => this.toggleDrawerOpen()}
            placement='right'
          >{this.props.browser}</Drawer>}
          <Drawer
            open={this.state.menuOpen}
            width={isMedia('handheld') ? '80vw' : '300px'}
            handler={false}
            onMaskClick={() => this.toggleMenuOpen()}
          >
            <Row spaced>
              <Link isNav menu key='day-planner-link' to={`/day-planner/${currentWeekstamp()}`}>Day Planner</Link>
              <Link isNav menu key='scratch-pad-link' to='/scratch-pad'>Scratch Pad</Link>
              <Link isNav menu key='topics-link' to='/topics'>Topics</Link>
              <br /><br />
              <Link isNav menu key='profile-link' to='/profile'>Your Profile</Link>
              <Link isNav menu key='support-link' to='/support'>Support</Link>
              <Link isNav menu key='signout-link' onClick={() => {
                authConnection.signOut()
                window.location = '/'
              }}>Sign out</Link>
            </Row>
          </Drawer>

          {this.props.detail}

        </div>}

        {!isMedia('small') && <div className={css(styles.flexContainer)}>
          <div className={css(styles.flexDetailContainer, !this.props.browser && styles.flexDetailContainer__noBorder)}>{this.props.detail}</div>
          {!!this.props.browser && <div className={css(styles.flexBrowserContainer)}>{this.props.browser}</div>}
        </div>}
      </div>
    </div>
  }
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailContainer: {
    flex: 1,
    marginBottom: '64px'
  },
  flexContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row'
  },
  flexBrowserContainer: {
    backgroundColor: '#fff',
    width: '300px',
    overflowY: 'scroll',
    maxHeight: '100vh'
  },
  flexDetailContainer: {
    flex: 1,
    backgroundColor: colors.offWhite,
    borderRight: '1px solid ' + colors.greyDim,
  },
  flexDetailContainer__noBorder: {
  	borderRight: 'none'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: colors.offWhite,
    borderBottom: '1px solid ' + colors.greyDim,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: layers.floatingUI
  },
  bottomBar: {
    maxWidth: '340px',
    borderRight: '1px solid #c8c8c8',
    overflowY: 'scroll',
    [breakpoints.handheld]: {
      maxWidth: '100%',
      zIndex: layers.floatingUI
    },
  },
  bottomBarDrawerOpen: {
    boxShadow: '4px 0 14px -7px rgba(0,0,0,0.4)'
  }
})
