import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import uuidv4 from 'uuid/v4'
import toArray from 'lodash.toarray'
import sortBy from 'lodash.sortby'
import { css, StyleSheet } from 'aphrodite'
import DayHeader from '../chunks/DayHeader'
import dbConnection from '../../store/dbConnection'
import DayDetail from '../chunks/DayDetail'
import { currentUser } from '../../store/authConnection'
import WeekStore from '../../store/WeekStore'
import Note from '../chunks/Note'
import { currentTimestamp, currentWeekstamp, currentDate, currentDatestamp } from '../chunks/DateFormat'
import NoteForm from '../chunks/NoteForm'
import NoteStore from '../../store/NoteStore'
// import { DayPlannerWeekBrowser } from './DayPlanner'
import BrowserWithDetail from '../layout/BrowserWithDetail'
import Loader from '../chunks/Loader'
import breakpoints, { isMedia } from '../breakpoints'
import BottomBar from '../chunks/BottomBar'

class ScratchPad extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    weekstamp: PropTypes.string.isRequired,
    onClickAddNote: PropTypes.func.isRequired
  }

  notes = []

  constructor (props) {
  	super(props)

  	this.notesCollectionRef = dbConnection.collection('scratchpads/' + currentUser().uid + '/notes')
  }

  async componentDidMount () {
    this._setUpNotesListener(currentUser().uid)

    try {
      // Check for older format scratchpads
      const mine = await dbConnection.collection('scratchpads').where('userId', '==', currentUser().uid).get()
      mine.forEach(async d => {
        if (d.id !== currentUser().uid) {
          const notes = toArray(d.get('notes'))

          notes.length && notes.forEach(note =>
          	this.notesCollectionRef.doc(note.id).set(note))

          d.ref.delete()
        }
      })
    } catch (err) {
      // console.error(err)
    }
  }

  _setUpNotesListener = async (userId) => {
    this.unsub && this.unsub()

    const scratchRef = dbConnection.collection('scratchpads').doc(userId)

    const existingScratchDoc = await scratchRef.get()
    !existingScratchDoc.exists && scratchRef.set({userId: currentUser().uid, created_at: Number(new Date())})

    this.notesCollectionRef = scratchRef.collection('notes')

    this.unsub = this.notesCollectionRef.onSnapshot(noteDocs => {
      this.notes = sortBy(noteDocs.docs.map(n => n.data()), n => -n.timestamp)
      this.forceUpdate()
    }, err => {})
  }

  _deleteNote = async noteId => {
    try {
      this.notesCollectionRef.doc(noteId).delete()
    } catch (err) {
      console.error(err)
    }
  }

  componentWillUnmount () {
    this.unsub()
  }

  _prepareNewNote () {
    const note = {
    	id: uuidv4(),
      userId: currentUser().uid,
      timestamp: currentTimestamp(),
      datestamp: currentDatestamp(),
      title: '',
      body: ''
    }

    return note
  }

  ensureClosedDrawer () {
    this.drawer.close()
  }

  _handleFinish = note => {
		this.notesCollectionRef.doc(note.id).set(note)
	  this.props.history.push('/scratch-pad')
  }

  render () {
    return <div>
        <BrowserWithDetail
          title='Scratch Pad'
          ref={d => this.drawer = d}
          browser={null/*<DayPlannerWeekBrowser
            navigate={(weekstamp, datestamp, dayId, noteId) => this.props.history.push(noteId ? `/scratch-pad/notes/${noteId}/edit` : this.props.history.push(`/day-planner/${weekstamp}/${datestamp}`))}
            weekStore={this.props.weekStore}
            history={this.props.history}
            weekstamp={this.props.weekstamp}
            handleClickTitle={() => this.props.history.push(`/day-planner/${currentWeekstamp()}/${currentDate()}`)}
            dayWillChange={d => {
              this.ensureClosedDrawer()
            }}
          />*/}
          bottomBar={isMedia('small', () => this.forceUpdate()) ? <BottomBar
            active='/scratch-pad'
            jumpToPlanner={() => this.props.history.push('/')}
            jumpToToday={() => this.props.history.push(`/day-planner/${currentWeekstamp()}/${currentDate()}`)}
            jumpToScratchPad={() => this.props.history.push('/scratch-pad')}
          /> : null}
          detail={<div>
            <Route key='view' exact path='/scratch-pad' render={({match}) => <div className={css(styles.container)}>
			        <header className={css(styles.header)}>
			          {!!this.notes.length && <DayHeader
			            breadcrumbs={[{path: `/day-planner/${currentWeekstamp()}`, name: 'Day Planner'}]}
			            title={<span>Scratch Pad</span>}
			            onClickAddNote={() => this.props.history.push('/scratch-pad/notes/add')}
			          />}
			        </header>
			        <div className={css(styles.notes)}>
			          {this.notes.map(note => <Note
                  key={note.id}
                  title={note.title}
                  note={note.body}
                  topics={note.topics}
                  timestamp={note.timestamp}
                  isComplete={note.isComplete}
                  isShownInSummary={true}
                  color={note.color}
                  onClickDone={() => {}}
                  toggleShowInSummary={() => {}}
                  setPaperColor={() => {}}
                  onClickNote={() => this.props.history.push(
                    `/scratch-pad/notes/${note.id}/edit`
                  )}
                  onClickTopic={slug => this.props.history.push(`/topics/${slug}`)}
                  handleCopy={() => {}}
                  handleMove={() => {}}
                  handleDelete={() => this.notesCollectionRef.doc(note.id).delete()}
                />)}
			        </div>
			      </div>} />
            <Route key='add-note' exact path='/scratch-pad/notes/add' render={({match}) => {
              const note = this._prepareNewNote()

              return <NoteForm
                isScratchPad
                note={note}
                onFinish={this._handleFinish}
                onCancel={() => this.props.history.push(`/scratch-pad`)}
              />
            }} />
            <Route key='edit-note' exact path='/scratch-pad/notes/:noteId/edit' render={({match}) => {
              const note = this.notes.find(n => n.id === match.params.noteId)

              return !note ? <Loader /> : <NoteForm
                isScratchPad
                note={note}
                quickSave={note => this.notesCollectionRef.doc(note.id).set(note)}
                onFinish={this._handleFinish}
                onCancel={() => this.props.history.push(`/scratch-pad`)}
              />
            }} />
          </div>}
        />
    </div>
  }
}

const styles = StyleSheet.create({
  container: {
    padding: '40px 36px',
    maxWidth: '800px',
    margin: '0 auto',
    [breakpoints.small]: {
      padding: '16px 4px'
    }
  },
  header: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  notes: {
    padding: '32px 0',
  }
})

export default ScratchPad
