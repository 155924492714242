import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import { UiLabel } from '../text'
import colors from '../colors'

class TextInput extends React.PureComponent {
  static propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    onReturn: PropTypes.func,
    autofocus: PropTypes.bool,
    large: PropTypes.bool,
    placeholder: PropTypes.string,
    hasFocus: PropTypes.bool,
    type: PropTypes.string,
    isRequired: PropTypes.bool
  }

  static defaultProps = {
    placeholder: '',
    type: 'text'
  }

  componentDidUpdate () {
    this.props.hasFocus && this.input.focus()
  }

  render () {
    return <div className={css(styles.container, this.props.large && styles.containerLarge)}>
      {this.props.label && <label className={css(styles.label)} htmlFor={this.props.label}>
        <UiLabel>{this.props.label}</UiLabel>
      </label>}
      <input
        ref={i => { this.input = i }}
        type={this.props.type}
        required={this.props.isRequired}
        placeholder={this.props.placeholder}
        autoFocus={this.props.autofocus}
        id={this.props.label ? this.props.label : ''}
        onChange={this.props.onChange}
        className={css(styles.textInput, this.props.large && styles.textInputLarge)}
        defaultValue={this.props.defaultValue}
        onKeyDown={e => {
          if (this.props.onReturn && e.key === 'Enter') {
            e.preventDefault()
            this.props.onReturn(e)
          }
        }}
      />
    </div>
  }
}

const styles = StyleSheet.create({
  container: {
    borderBottom: '1px solid #c8c8c8',
    marginBottom: '16px'
  },
  containerLarge: {
    borderBottom: 'none'
  },
  label: {
    display: 'block',
    fontWeight: 'bold',
    padding: '0 4px',
  },
  textInput: {
    color: '#415552',
    width: '100%',
    fontSize: '16px',
    lineHeight: '24px',
    border: 'none',
    padding: 0,
    ':focus': {
      outline: 'none',
      background: colors.offWhite,
      boxShadow: 'inset 0 0 4px 0 ' + colors.greyDim
    },
    '::placeholder': {
      opacity: 0.5
    }
  },
  textInputLarge: {
    fontSize: '20px',
    fontWeight: 'bold'
  }
})

export default TextInput
