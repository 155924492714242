import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import DOMPurify from 'dompurify'
import linkifyStr from 'linkifyjs/string'
import slug from 'slug'
import Ionicon from 'react-ionicons'
import Popover from './Popover'
// import IconButton from '../controls/IconButton'
import Pill from './Pill'
import Menu from '../controls/Menu'
import {Heading, BodyText} from '../text'
import colors from '../colors'
import { timestampToReadableTime, specificMonthstamp, specificReadableDate } from './DateFormat';
import breakpoints from '../breakpoints';
import Link from '../controls/Link';
import layers from '../layers';

class Note extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    note: PropTypes.string,
    topics: PropTypes.arrayOf(PropTypes.string),
    condensed: PropTypes.bool,
    isComplete: PropTypes.bool,
    isShownInSummary: PropTypes.bool,
    color: PropTypes.oneOf(['white']),
    onClickDone: PropTypes.func,
    toggleShowInSummary: PropTypes.func,
    setPaperColor: PropTypes.func,
    onClickNote: PropTypes.func,
    onClickTopic: PropTypes.func,
    handleCopy: PropTypes.func,
    handleMove: PropTypes.func,
    handleDelete: PropTypes.func,
    timestamp: PropTypes.number.isRequired,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    isScratchPad: PropTypes.bool
  }

  static defaultProps = {
    title: '',
    note: '',
    topics: [],
    isComplete: false,
    isShownInSummary: false,
    color: 'white'
  }

  toUrl (timestamp) {
    return this.props.isScratchPad
      ? `/scratch-pad/notes/${this.props.id}/edit`
      : `/day-planner/${specificMonthstamp(timestamp)}/${specificReadableDate(timestamp)}/note/${timestampToReadableTime(timestamp)}/edit`
  }

  render () {
    return <div className={css(
      styles.container,
      this.props.condensed && styles.containerCondensed,
      !!this.props.onClickNote && styles.containerClickable
    )} onClick={this.props.onClickNote}>
      {/* <div className={css(styles.controls)}>
        <IconButton iconName='checkbox-outline' onClick={this.props.onClickDone} />
      </div> */}
      <div className={css(styles.content, this.props.condensed && styles.contentCondensed)}>
        {this.props.condensed
          ? <Link to={this.toUrl(this.props.timestamp)}>
            <BodyText inline>{!!this.props.title
              ? this.props.title
              : this.props.note.substring(0, 20)}</BodyText>
            &nbsp;Edit
          </Link>
          : <Heading>{this.props.title}</Heading>}

        {!this.props.condensed && <BodyText pre>
        	<div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(linkifyStr(this.props.note))}} />
      	</BodyText>}

        <div className={css(styles.topics)}>
          {this.props.topics.map(topic =>
            <Pill key={topic} onClick={() => this.props.onClickTopic(slug(topic))}>{topic}</Pill>)
          }
        </div>
      </div>
      {!this.props.condensed && <div className={css(styles.extras)}>
        {!!this.props.handleDelete && <Popover
          trigger={<Ionicon
            icon={`md-more`}
            fontSize='35px'
            color='#039179'
          />}
          side='right'
        >
          <Menu items={[
            // {label: 'Hide from summary', onClick: this.props.toggleShowInSummary},
            // {label: 'Paper color', onClick: this.props.setPaperColor},
            // {label: 'Copy to…', onClick: this.props.handleCopy},
            // {label: 'Move to…', onClick: this.props.handleMove},
            {label: 'Delete', onClick: this.props.handleDelete},
          ]} />
        </Popover>}
      </div>}
    </div>
  }
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    zIndex: layers.base,
    borderRadius: '16px',
    // border: `1px solid ${colors.greyDim}`,
    padding: '16px 12px',
    marginBottom: '40px',
    display: 'flex',
    flexDirection: 'row',
    minHeight: '111px',
    alignItems: 'flex-start',
    backgroundColor: colors.white,
    boxShadow: '0 2px 3px -2px rgba(0,0,0,0.2)',
    [breakpoints.handheld]: {
      boxShadow: 'none'
    }
  },
  containerClickable: {
    cursor: 'pointer'
  },
  containerCondensed: {
    padding: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    marginTop: 0,
    marginBottom: '8px',
    minHeight: 'none'
  },
  controls: {
    flex: 0
  },
  content: {
    flex: 1,
    padding: '0 8px'
  },
  contentCondensed: {
    padding: 0
  },
  extras: {
    flex: 0
  }
})

export default Note
