import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import Ionicon from 'react-ionicons'
import colors from '../colors'

class Pill extends React.PureComponent {
  static propTypes = {
    children: PropTypes.string.isRequired,
    onRemove: PropTypes.func,
    onClick: PropTypes.func
  }

  render () {
    return <div onClick={this.props.onClick
      ? e => {
        e.preventDefault()
        e.stopPropagation()
        this.props.onClick()
      }
      : () => {}
    } className={css(
      styles.pill,
      this.props.onClick && styles.clickable
    )}>
      {this.props.onRemove && <Ionicon
        icon='md-close'
        fontSize='14px'
        onClick={() => this.props.onRemove(this.props.children)}
        color={colors.coral}
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          margin: '-3px 4px 0 -5px',
          cursor: 'pointer'
        }}
      />}
      {this.props.children}
    </div>
  }
}

const styles = StyleSheet.create({
  pill: {
    display: 'inline-block',
    color: colors.greenMuted,
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '22px',
    padding: '0 12px',
    margin: '0 8px 0 0',
    borderRadius: '4px',
    backgroundColor: colors.greyLight
  },
  clickable: {
    cursor: 'pointer',
    transition: 'background-color 0.1s ease-in-out',
    ':hover, :focus': {
      color: colors.greenDark
    }
  }
})

export default Pill
