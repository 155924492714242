import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { PageTitle, BodyText, SectionTitle, Heading, UiLabel } from '../../text'
import { PublicContainer } from '../Register'
import Link from '../../controls/Link'
import Button from '../../controls/Button';

export class ContentPage extends React.PureComponent {
  render () {
    const {children} = this.props

    return <PublicContainer>
      <div className={css(styles.contentPage)}>{children}</div>
    </PublicContainer>
  }
}

class HowItWorks extends React.PureComponent {
  render () {
    return <ContentPage>
      <PageTitle>How it works</PageTitle>

      <br />
      <Heading>Mission</Heading>
      <BodyText>
        This app works really well, because it's super basic. Our current goals for the app are:<br /><br />
        - Reduce the amount of time between a) an idea sparks into existence, and b) a simple form of the idea gets recorded in writing<br />
        - Reduce the amount of time spent planning simple things<br />
        - Improve the success rate of completing tasks and executing ideas<br />
        - Provide a tool for visualizing an idea's relative momentum<br />
        - Keep it super simple
        <br /><br />

        We're still working on it!
      </BodyText>

      <br />
      <Heading>Day Planner</Heading>
      <BodyText>Week Notes is a tool to quickly record thoughts, ideas, memos, plans, whatever. You can get writing immediately when you need to, without needing to decide where to put your text. Just throw your text in "today," and be on your way. When you come back to the app later in the day, or maybe the next day, you'll see your recent notes, nicely organized.</BodyText>

      <br />
      <Heading>Topics</Heading>
      <BodyText>Topics allow you to link your note with a topics, like "work." Later, when you're looking for your work notes, simply go to the "work" topic page and you'll see all your work notes.</BodyText>

      <br />
      <Heading>Scratch Pad</Heading>
      <BodyText>The scratch pad is a single static page, where you can save notes that seem kinda ongoing or maybe longer than a single day.</BodyText>

      <br />
      <br />

      <SectionTitle>Support</SectionTitle>
      <BodyText spaced>Week Notes is growing. We're eager to hear any feedback you might have to offer, good or bad. Currently, the only option for submitting feedback is via the <Link to='/support'>Support page</Link>.</BodyText>

    </ContentPage>
  }
}

class Support extends React.PureComponent {
  render () {
    return <ContentPage>
      <PageTitle>WeekNotes Support</PageTitle>

      <BodyText spaced>Hi! Your best bet is the little chat icon down in the bottom right. Click it and a human being will help you within a day or so, usually within a couple hours.</BodyText>
    </ContentPage>
  }
}

class Privacy extends React.PureComponent {
  render () {
    return <ContentPage>

      <PageTitle>Privacy policy</PageTitle>

      <BodyText spaced>
        This privacy policy governs your use of the software application Week Notes (“Application”) for web and mobile devices that was created by Kevin Doole. The Application is a note-taking app.
      </BodyText>

      <SectionTitle>
        What information does the Application obtain and how is it used?
      </SectionTitle>

      <Heading>
        User Provided Information
      </Heading>

      <BodyText spaced>
        The Application only asks for an email address when you submit feedback / support. We only use this information to reply to your message, if necessary.
      </BodyText>

      <Heading>
        Automatically Collected Information
      </Heading>

      <BodyText spaced>
        In addition, the Application may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile devices unique device ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browsers you use, and information about the way you use the Application.
      </BodyText>

      <UiLabel large>
        Does the Application collect precise real time location information of the device?
      </UiLabel>

      <BodyText spaced>
        This Application does not collect precise information about the location of your mobile device.
      </BodyText>

      <UiLabel large>
        Do third parties see and/or have access to information obtained by the Application?
      </UiLabel>

      <BodyText spaced>
        Only aggregated, anonymized data is periodically transmitted to external services to help us improve the Application and our service. We will share your information with third parties only in the ways that are described in this privacy statement.
      </BodyText>

      <UiLabel large>
        We may disclose User Provided and Automatically Collected Information:
      </UiLabel>

      <BodyText spaced>
        - as required by law, such as to comply with a subpoena, or similar legal process<br />
        - when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request<br />
        - with our trusted services providers who work on our behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement<br />
        - if Kevin Doole is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of this information, as well as any choices you may have regarding this information.
      </BodyText>

      <UiLabel large>
        What are my opt-out rights?
      </UiLabel>

      <BodyText spaced>
        You can stop all collection of information by the Application easily by uninstalling the Application. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.
      </BodyText>

      <UiLabel large>
        Data Retention Policy, Managing Your Information
      </UiLabel>

      <BodyText spaced>
        We will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. We will retain Automatically Collected information for up to 24 months and thereafter may store it in aggregate. If you’d like us to delete User Provided Data that you have provided via the Application, please contact us at support@weeknotes.app and we will respond in a reasonable time. Please note that some or all of the User Provided Data may be required in order for the Application to function properly.
      </BodyText>

      <SectionTitle>
        Children
      </SectionTitle>

      <BodyText spaced>
        We do not use the Application to knowingly solicit data from or market to children under the age of 13. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us at support@weeknotes.app. We will delete such information from our files within a reasonable time.
      </BodyText>

      <SectionTitle>
        Security
      </SectionTitle>

      <BodyText spaced>
        We are concerned about safeguarding the confidentiality of your information. Please be aware that, although we aim to provide reasonable security for information we process and maintain, no security system can prevent all potential security breaches.
      </BodyText>

      <SectionTitle>
        Changes
      </SectionTitle>

      <BodyText spaced>
        This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy here and in our release notes on this website and on the App Store. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.
      </BodyText>

      <SectionTitle>
        Your Consent
      </SectionTitle>

      <BodyText spaced>
        By using the Application, you are consenting to our processing of your information as set forth in this Privacy Policy now and as amended by us. “Processing,” means using cookies on a computer/hand held device or using or touching information in any way, including, but not limited to, collecting, storing, deleting, using, combining and disclosing information, all of which activities will take place in Canada. If you reside outside the United States your information will be transferred, processed and stored there under Canadian privacy standards.
      </BodyText>

      <SectionTitle>
        Contact us
      </SectionTitle>

      <BodyText spaced>
        If you have any questions regarding privacy while using the Application, or have questions about our practices, please contact us via email at support@weeknotes.app.
      </BodyText>

    </ContentPage>
  }
}

class Terms extends React.PureComponent {
  render () {
    return 'terms'
  }
}

const styles = StyleSheet.create({
  contentPage: {
    maxWidth: '700px',
    margin: '40px auto',
    padding: '0 8px'
  }
})

export {HowItWorks, Support, Privacy, Terms}
