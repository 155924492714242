import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'

class PageTitle extends React.PureComponent {
  static propTypes = {
    children: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    center: PropTypes.bool,
    small: PropTypes.bool,
    tall: PropTypes.bool
  }

  render () {
    return <div className={css(
      styles.pageTitle,
      this.props.isLoading && styles.loading,
      this.props.center && styles.center,
      this.props.small && styles.small,
      this.props.tall && styles.tall
    )}>
      {this.props.children} {this.props.isLoading &&
        <div className={css(styles.loader, this.props.small && styles.loaderSmall)} />
      }
    </div>
  }
}

const loaderFeyframes = {
  '0%': {
      transform: 'rotate(0deg)',
  },
  '100%': {
      transform: 'rotate(360deg)'
  }
}

const styles = StyleSheet.create({
  pageTitle: {
    color: '#415552',
    fontSize: '31px',
    lineHeight: '37px',
    fontWeight: '700'
  },
  center: {
    textAlign: 'center'
  },
  small: {
    fontSize: '16px',
    lineHeight: 1,
    fontWeight: '700'
  },
  tall: {
    lineHeight: '44px'
  },
  loading: {
    opacity: 0.6
  },
  loader: {
    display: 'inline-block',
    margin: '2px',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    animationName: [loaderFeyframes],
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    border: '2px solid rgba(0, 0, 0, 0.3)',
    borderTop: '2px solid rgba(0, 0, 0, 1)'
  },
  loaderSmall: {
    marginTop: '8px',
    width: '12px',
    height: '12px',
  }
})

export default PageTitle
