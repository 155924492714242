import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

var config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
}

firebase.initializeApp(config)

export default firebase
