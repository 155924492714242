import React from 'react'
import PropTypes from 'prop-types'
import { UiLabel, Heading } from '../text'
import IconButton from '../controls/IconButton'
import { css, StyleSheet } from 'aphrodite';
import Link from '../controls/Link';

class DayHeader extends React.PureComponent {
  static propTypes = {
    title: PropTypes.node,
    onClickAddNote: PropTypes.func,
    breadcrumbs: PropTypes.arrayOf(
      PropTypes.shape({path: PropTypes.string, name: PropTypes.string})
    ).isRequired
  }

  render () {
    return <UiLabel large>
    <div>
      {this.props.breadcrumbs.map(b => <div key={b.path} style={{display: 'inline-block'}}>
        <Link small to={b.path}>{b.name}</Link><UiLabel inline>&nbsp;/&nbsp;</UiLabel>
      </div>)}

      {this.props.title && <Heading inline>{this.props.title}</Heading>}

      {this.props.onClickAddNote && <div className={css(styles.addNote)}><IconButton
        size={28}
        highlight
        text='another note'
        iconName='add'
        onClick={this.props.onClickAddNote}
      /></div>}
    </div>
  </UiLabel>
  }
}

const styles = StyleSheet.create({
  addNote: {
    marginTop: '16px'
  }
})

export default DayHeader
