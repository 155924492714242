import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import { Link as RRDLink } from 'react-router-dom'
import colors from '../colors';

class Link extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.string,
    href: PropTypes.string,
    heavy: PropTypes.bool,
    muted: PropTypes.bool,
    dark: PropTypes.bool,
    small: PropTypes.bool,
    menu: PropTypes.bool,
    onClick: PropTypes.func,
    isNav: PropTypes.bool
  }

  constructor (props) {
    super(props)

    this.linkStyles = css(
      styles.link,
      props.heavy && styles.heavy,
      props.dark && styles.dark,
      props.muted && styles.muted,
      props.small && styles.small,
      props.menu && styles.menu,
      (props.isNav && window.location.pathname.indexOf(this.props.to) === 0) && styles.activeLink
    )
  }

  render () {
    const Component = this.props.to
      ? RRDLink
      : this.props.href ? 'a' : 'div'

    return this.props.to
      ? <Component
          to={this.props.to}
          className={this.linkStyles}
        >
          {this.props.children}
        </Component>
      : this.props.href
        ? <Component className={this.linkStyles} href={this.props.href}>
            {this.props.children}
          </Component>
        : this.props.onClick
          ? <Component onClick={this.props.onClick} className={this.linkStyles}>
              {this.props.children}
            </Component>
          : <Component className={this.linkStyles}>{this.props.children}</Component>
  }
}

const styles = StyleSheet.create({
  link: {
    cursor: 'pointer',
    color: '#039179',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline'
    }
  },
  activeLink: {
    cursor: 'default',
    color: colors.greenDark,
    fontWeight: 'normal',
    backgroundColor: colors.greenLight
  },
  heavy: {
    fontWeight: '800'
  },
  dark: {
    color: colors.greenMutedDark
  },
  muted: {
    color: colors.greenMuted,
    opacity: 0.7
  },
  small: {
    fontSize: '12px'
  },
  menu: {
    fontSize: '16px',
    width: '100%',
    display: 'block',
    textAlign: 'left',
    padding: '16px 60px',
    fontWeight: 'bold'
  }
})

export default Link
