import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'

class Heading extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    small: PropTypes.bool,
    secondary: PropTypes.bool,
    inline: PropTypes.bool
  }

  render () {
    return <div className={css(
      styles.heading,
      this.props.small && styles.small,
      this.props.secondary && styles.secondary,
      this.props.inline && styles.inline
    )}>
      {this.props.children}
    </div>
  }
}

const styles = StyleSheet.create({
  heading: {
    color: '#415552',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '700'
  },
  small: {
    fontSize: '12px',
  },
  secondary: {
    opacity: 0.3
  },
  inline: {
    display: 'inline-block'
  }
})

export default Heading
