import React, { Component } from 'react'
import { Offline } from 'react-detect-offline'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import './App.css'
import Ionicon from 'react-ionicons'
import { currentWeekstamp, readableDateToDatestamp, datestampToReadableDate, specificWeekstamp, currentDate, tomorrowDatestamp, specificReadableDate, isMoreThanThreeDaysAgo, timestampToReadableTime, datestampToWeekstamp } from './components/chunks/DateFormat'
import dbConnection from './store/dbConnection'
import authConnection, { currentUser, isGoogleAuth } from './store/authConnection'
import Link from './components/controls/Link'
import WeekStore, { PermissionError, NotFoundError } from './store/WeekStore'
import DayPlanner from './components/screens/DayPlanner'
import ScratchPad from './components/screens/ScratchPad'
import Register from './components/screens/Register'
import { HowItWorks, Support, Privacy, Terms, ContentPage } from './components/screens/content'
import WeekSummary from './components/screens/WeekSummary'
import Login from './components/screens/Login'
import TopBar from './components/chunks/TopBar'
import Popover from './components/chunks/Popover'
import Menu from './components/controls/Menu'
import colors from './components/colors'
import {isMedia} from './components/breakpoints'
import { UiLabel, PageTitle, BodyText, SectionTitle } from './components/text'
import Row from './components/chunks/Row'
// import LoadingMessage from './components/chunks/LoadingMessage'
import GoogleLogo from './components/images/Google_2015_logo.svg'
import TextInput from './components/controls/TextInput'
import Button from './components/controls/Button'
import LostPassword from './components/screens/LostPassword'
import TopicSummary from './components/screens/TopicSummary'
import { StyleSheet, css } from 'aphrodite';

export const NoMatch = () => <div style={{padding: '40px 0', backgroundColor: colors.coralLight}}>
  <PageTitle center>There's nothing here</PageTitle>
  <BodyText center>Lost? <Link to='/today'>Go to today</Link></BodyText>
  <BodyText center>Having trouble? <Link to='/support'>Get help</Link></BodyText>
</div>

const AppBottomBar = () => <div className={css(styles.footer)}>
   <Link to='/support'>Support</Link> · <Link to='/privacy'>Privacy</Link>
   <br />
   <Link href='https://digital-labs.ca/'>Digital Labs</Link>
</div>

class PublicWebsite extends React.PureComponent {
	componentDidMount () {
		authConnection.onAuthStateChanged(user => {
			this.forceUpdate()
		})
	}

	render () {
		return <Switch>
		  <Route exact path='/login' render={({history}) => <ContentPage><Login loginRedirectPath='/' /></ContentPage>} />
		  <Route exact path='/lost-password' component={LostPassword} />
		  <Route exact path='/how-it-works' component={HowItWorks} />
		  <Route exact path='/support' component={Support} />
		  <Route exact path='/privacy' component={Privacy} />
		  <Route exact path='/terms' component={Terms} />
		  <Route path='/' render={({history}) => <Register
		    loginRedirectPath='/'
		  />} />
		</Switch>
	}
}

const weekStore = new WeekStore(currentWeekstamp(), undefined, dbConnection)

class App extends Component {
	lastUserId

  state = {
    isAuthenticated: false,
    failedToFetch: false,
    failedToLoadMessage: '',
    isUpdatingProfile: false,
    editedUserName: '',
    editedUserEmail: '',
    needsReAuth: false,
    weekstampCursor: currentWeekstamp(),
    fetchedCursor: undefined
  }

  async componentDidMount () {
    this.dispose = authConnection.onAuthStateChanged(async user => {
      if (!!user) {
        this._login(user)

        if (this.lastUserId !== user.uid) {
        	window.dataLayer.push({event: 'userChanged', userId: user.uid})
        	this.lastUserId = user.uid
        }
      }
    })
  }

  componentDidCatch (error, errorInfo) {
    console.log(error)
    console.log(errorInfo)
    this.setState({failedToFetch: true});
  }

  failedToLoad (err) {
    console.error(err)
    if (err instanceof PermissionError) {
      this.setState({failedToFetch: true, failedToLoadMessage: 'You do not have permission to load this data.'});
    } else if (err instanceof NotFoundError) {
      this.setState({failedToFetch: true, failedToLoadMessage: 'No data found.'});
    } else {
      this.setState({failedToFetch: true, failedToLoadMessage: 'You\'ve run into some unknown error.'})
    }
  }

  componentWillUnmount () {
    'dispose' in this && this.dispose()
  }

  _login = (user) => {
    weekStore.setUserId(user.uid)

    this.setState({
      isAuthenticated: true,
      editedUserEmail: user.email,
      editedUserName: user.displayName
    })
  }

  _logout = () => {
    authConnection.signOut()
    this.setState({isAuthenticated: false})
    window.location = '/'
  }

  weeks = []

  render() {
    return <Router>
      {this.state.failedToFetch
        ? <div>Something has gone wrong. {this.state.failedToLoadMessage} <Link href='/today'>Back to today</Link></div>
        : <div className="App" style={{
          display: 'flex',
          flexDirection: 'column'
        }}>

        {!this.state.isAuthenticated ? <PublicWebsite /> : <div>
        	<div style={{flex: 1, backgroundColor: colors.offWhite}}>

            {isMedia('handheld', () => this.forceUpdate()) ? null : <Route path='/' render={({history}) => <div>
              <Offline><Row fixed>
                <UiLabel highlight center>
                  <span style={{
                    display: 'inline-block',
                    height: '18px',
                    verticalAlign: 'middle',
                    marginRight: '4px'
                  }}><Ionicon icon='ios-pulse' fontSize='16px' color={colors.coralDark} /></span>
                  You're not connected to the internet, and we're not set up for that yet. Some stuff might not work.
                </UiLabel>
              </Row></Offline>

              {(
              	!authConnection.currentUser.emailVerified &&
                isMoreThanThreeDaysAgo(authConnection.currentUser.metadata.creationTime)
              ) && <Row fixed error>
                <UiLabel highlight>Please check your email for a Week Notes message to verify your account.</UiLabel>
              </Row>}
            </div>} />}

            <Route render={({history}) => isMedia('large', () => this.forceUpdate()) &&
              <TopBar
                title={<Link heavy muted to='/'>Week Notes</Link>}
                menuItems={[
                  <Link muted key='topbar-day-planner-link' to={`/day-planner/${currentWeekstamp()}`}>Day Planner</Link>,
                  <Link muted key='topbar-scratch-pad-link' to='/scratch-pad'>Scratch Pad</Link>,
                  <Link muted key='topbar-topics-link' to='/topics'>Topics</Link>,
                  <Popover
                    side='right'
                    key='topbar-2'
                    trigger={<Link muted>
                      More <Ionicon icon='ios-arrow-down' fontSize='16px' color={colors.greenMuted} />
                    </Link>}
                  >
                    <Menu onClickedItem={() => window.dispatchEvent(new MouseEvent('click'))} items={[
                      {label: 'Profile', onClick: () => history.push('/profile')},
                      {label: 'Support', onClick: () => history.push('/support')},
                      {label: 'Logout', onClick: () => this._logout()}
                    ]} />
                  </Popover>
                ]} />}
            />

            <Switch>
            <Route exact path='/how-it-works' component={HowItWorks} />
            <Route exact path='/support' component={Support} />
            <Route exact path='/privacy' component={Privacy} />
            <Route exact path='/terms' component={Terms} />
            <Route exact path='/' render={() => <Redirect to={`/day-planner/${currentWeekstamp()}`} />} />
            <Route exact path='/today' render={() => <Redirect to={`/day-planner/${currentWeekstamp()}/${currentDate()}`} />} />
            <Route exact path='/tomorrow' render={() => <Redirect to={`/day-planner/${specificWeekstamp(tomorrowDatestamp())}/${specificReadableDate(tomorrowDatestamp())}`} />} />
            {/* <Route exact path='/last-week' render={() => <Redirect to={`/day-planner/${lastWeekstamp()}/${specificReadableDate(weekstampToReadableDate(lastWeekstamp()))}`} />} /> */}

            <Route path='/topics/:name?' render={({history, match}) => <TopicSummary
                history={history}
                jumpToNote={() => {}}
                jumpToPlanner={() => {}}
                jumpToToday={() => {}}
                jumpToScratchPad={() => {}}
              />
            } />

            <Route path='/scratch-pad' render={({match, history}) =>
              <ScratchPad
                history={history}
                weekstamp={this.state.weekstampCursor}
                onClickShowCompleted={() => {}}
                onClickAddNote={() => {}}
              />
            } />

            <Route exact path='/day-planner/:weekstamp/:datestamp/:addNoteOrNote?/:timestamp?/:edit?' render={({match, history}) => <DayPlanner
                history={history}
                onClickDay={datestamp => history.push(`/day-planner/${datestampToWeekstamp(datestamp)}/${datestampToReadableDate(datestamp)}`)}
                onChangeWeek={weekstamp => this.setState({weekstampCursor: weekstamp})}
                onClickAddNote={() => history.push(`/day-planner/${match.params.weekstamp}/${match.params.datestamp}/add-note`)}
                notesCollectionRef={dbConnection.collection('notes')}
                defaultWeekstamp={match.params.weekstamp}
                defaultDatestamp={readableDateToDatestamp(match.params.datestamp)}
              />
            } />

            <Route path='/day-planner/:weekstamp' render={({history, match}) => <WeekSummary
                weekstamp={match.params.weekstamp}
                history={history}
                onClickDay={datestamp => history.push(`/day-planner/${datestampToWeekstamp(datestamp)}/${datestampToReadableDate(datestamp)}`)}
                jumpToPlanner={() => history.push('/')}
                jumpToToday={() => history.push(`/day-planner/${currentWeekstamp()}/${currentDate()}`)}
                jumpToScratchPad={() => history.push('/scratch-pad')}
                jumpToDay={async date => {
                  history.push(`/day-planner/${specificWeekstamp(date)}/${datestampToReadableDate(date)}`)
                }}
                jumpToNote={async note => {
                  history.push(`/day-planner/${specificWeekstamp(note.datestamp)}/${datestampToReadableDate(note.datestamp)}/note/${timestampToReadableTime(note.timestamp)}/edit`)
                }}
                jumpToTopic={slug => history.push('/topics/' + slug)}
                onChangeWeek={weekstamp => {
                	this.setState({weekstampCursor: weekstamp})
                	history.push(`/day-planner/${weekstamp}`)
                }}
              />}
            />

            <Route exact path='/profile/edit' render={({history}) => {
              let password = ''

              return <div style={{
                maxWidth: '700px',
                margin: '0 auto',
                padding: '2em'
              }}>
                {this.state.needsReAuth && <div style={{
                  position: 'fixed',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  background: 'rgba(0,0,0,0.3)'
                }}>
                  <form style={{
                    position: 'absolute',
                    top: '30%',
                    left: '50%',
                  }} onSubmit={async e => {
                    e.preventDefault()
                    await authConnection.signInWithEmailAndPassword(currentUser().email, password)
                    await currentUser().updateEmail(this.state.editedUserEmail)
                    this.setState({isUpdatingProfile: false, needsReAuth: false})
                    history.push('/profile')
                  }}>
                    <div style={{
                      position: 'relative',
                      padding: '1rem 2rem',
                      width: '400px',
                      left: '-50%',
                      textAlign: 'center',
                      background: '#fff',
                      border: '1px solid #e0e0e0'
                    }}>
                      <TextInput type='password' label='Please confirm your password' onChange={e => password = e.target.value} />
                      <Button>Continue</Button>
                    </div>
                  </form>
                </div>}

                <div style={{paddingTop: '24px'}}>
                  <PageTitle isLoading={this.state.isUpdatingProfile}>Your Week Notes account</PageTitle>

                  <SectionTitle>Identity</SectionTitle>
                  <form onSubmit={async e => {
                    e.preventDefault()
                    this.setState({isUpdatingProfile: true})
                    if (this.state.editedUserName !== currentUser().displayName) {
                      await currentUser().updateProfile({
                        displayName: this.state.editedUserName,
                      })
                    }

                    if (this.state.editedUserEmail !== currentUser().email) {
                      try {
                        await currentUser().updateEmail(this.state.editedUserEmail)
                      } catch (err) {
                        if (err.code === 'auth/requires-recent-login') {
                          this.setState({needsReAuth: true})
                          return
                        }
                      }
                    }

                    this.setState({isUpdatingProfile: false})
                    history.push('/profile')
                  }}>
                    <TextInput
                      defaultValue={currentUser().displayName}
                      onChange={e => this.setState({editedUserName: e.target.value})}
                      label='Name'
                    />
                    <TextInput
                      defaultValue={currentUser().email}
                      onChange={e => this.setState({editedUserEmail: e.target.value})}
                      label='Email'
                    />
                    <Button>Save</Button>
                  </form>
                </div>
              </div>
            }} />

            <Route path='/profile' render={() => <div style={{
              maxWidth: '700px',
              margin: '0 auto',
              padding: '2em'
            }}>
              <PageTitle>Your Week Notes account</PageTitle>

              {isGoogleAuth()
                ? <div style={{paddingTop: '24px'}}>
                  <BodyText spaced>Signed in using</BodyText>
                  <img
                    height='24px'
                    src={GoogleLogo}
                    alt='Google Logo'
                    style={{paddingRight: '24px', display: 'inline-block', verticalAlign: 'middle'}}
                  />
                  <img style={{
                    height: '24px',
                    width: '24px',
                    borderRadius: '24px',
                    display: 'inline-block',
                    marginRight: '8px',
                    verticalAlign: 'sub'
                  }} src={currentUser().photoURL} alt='Your Google avatar' />
                  <BodyText inline spaced>{currentUser().email}</BodyText>
                </div>
                : <div style={{paddingTop: '24px'}}>
                  <SectionTitle>Identity</SectionTitle>
                  <BodyText spaced>Name: {currentUser().displayName}</BodyText>
                  <BodyText spaced>Email: {currentUser().email}</BodyText>
                  <Link to='/profile/edit'>Edit</Link>
                </div>
              }

            </div>} />

            <Route component={NoMatch} />
          </Switch>
        </div></div>}
        {(this.state.isAuthenticated && isMedia('large', () => this.forceUpdate())) && <AppBottomBar />}
      </div>}
    </Router>
  }
}

export default App;

const styles = StyleSheet.create({
  footer: {
    zIndex: 3,
    flex: 0,
    position: 'relative',
    textAlign: 'center',
    padding: '16px 0',
    lineHeight: 1.5,
    borderTop: '3px solid ' + colors.pinkShaded,
    backgroundColor: colors.pinkUltraLight
  }
})
