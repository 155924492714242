import React from 'react'
import PropTypes from 'prop-types'
import firebase from '../../store/firebase'
import ui from '../../store/authUi'
import Link from '../controls/Link';
import { PageTitle } from '../text';

class Login extends React.PureComponent {
  static propTypes = {
    loginRedirectPath: PropTypes.string.isRequired
  }

  pendingRedirect = false

  componentDidMount () {
  	const el = document.getElementById('firebaseui-login-auth-container')
  	if (el) {
    	this.pendingRedirect = ui.isPendingRedirect()
  	}
    ui.start('#firebaseui-login-auth-container', {
      signInSuccessUrl: this.props.loginRedirectPath,
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
      ]
    })
  }

  render () {
    return <div style={{
      textAlign: 'center',
      marginTop: '40px'
    }}>
      <PageTitle>Log in</PageTitle>
      <div id='firebaseui-login-auth-container'></div>
      <Link href='/lost-password'>Lost your password?</Link>
    </div>
  }
}

export default Login
