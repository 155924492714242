import React from 'react'
import PropTypes from 'prop-types'
import { css, StyleSheet } from 'aphrodite'
import firebase from '../../store/firebase'
import authConnection, { currentUser } from '../../store/authConnection'
import ui from '../../store/authUi'
import GetStarted from './GetStarted'
import TopBar from '../chunks/TopBar'
import Link from '../controls/Link'
import colors from '../colors'
import TextInput from '../controls/TextInput'
import Button from '../controls/Button'
import LoadingMessage from '../chunks/LoadingMessage'
import breakpoints, { isMedia } from '../breakpoints'
import { UiLabel } from '../text'

const newUser = {}

export class PublicTopBar extends React.PureComponent {
  render () {
    return <div className={css(styles.topBar)}>
      <TopBar pink title={<Link heavy href='/'>Week Notes</Link>} menuItems={[
        <Link key='more-info' href='/how-it-works'>How it works</Link>,
        <Link key='support' href='/support'>Support</Link>,
        <Link key='login' href='/login'>Login</Link>,
        <Link heavy key='register' href='/register'>Register</Link>
      ]} />
    </div>
  }
}

export class PublicBottomBar extends React.PureComponent {
  render () {
    return <div className={css(styles.footer)}>
      © 2018 <Link href='https://digital-labs.ca/'>Digital Labs</Link> · <Link to='/support'>Support</Link> · <Link to='/privacy'>Privacy</Link>
    </div>
  }
}

export const PublicContainer = ({children}) => <div className={css(styles.outerContainer)}>
  {!currentUser()
    ? <div style={{
    	display: 'flex',
    	flexDirection: 'column',
    	alignItems: 'stretch',
    	height: '100%'
    }}>
      <div style={{flex: 0}}><PublicTopBar /></div>
      <div style={{flex: 1}}>{children}</div>
      <div style={{flex: 0}}><PublicBottomBar /></div>
    </div>
    : children
  }
</div>

class Register extends React.Component {
  static propTypes = {
    loginRedirectPath: PropTypes.string.isRequired,
    isReady: PropTypes.bool
  }

  pendingRedirect = false

  state = {
    errors: false,
    errorMessage: ''
  }

  componentDidMount () {
    this.pendingRedirect = ui.isPendingRedirect()
    ui.start('#firebaseui-auth-container', {
      signInSuccessUrl: this.props.loginRedirectPath,
      tosUrl: 'https://weeknotes.app/privacy/',
      privacyPolicyUrl: 'https://weeknotes.app/privacy/',
      signInFlow: 'redirect',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
      ]
    })
  }

  render () {
    const isIOS = !!window.navigator.platform && /iPhone|iPod/.test(window.navigator.platform)

    if (this.pendingRedirect) {
      return <div>
        <LoadingMessage message='Logging you in' />
        <div id='firebaseui-auth-container'></div>
      </div>
    }

    return <PublicContainer>

      <div className={css(styles.container)}>

        {!isMedia('large', () => this.forceUpdate()) && <div>
          <GetStarted onlyHeading />
        </div>}

        <div className={css(styles.loginFormContainer)}>
          {/* TODO: Turn this on when apps ready */}
          {(false && isIOS) && <div style={{textAlign: 'center', margin: '24px', fontWeight: 'bold'}}>
            Create an account when you install the iPhone app.<br /><br />
            <Link href='https://itunes.apple.com/us/app/weeknotes-day-planner-memos/id1435127501'>
              <img style={{width: '60%'}} src="/app-store-button.png" alt="Install the iPhone app to set up your account."/>
            </Link>
            <br />
            or
          </div>}


          <div style={{
            textAlign: 'center',
            fontWeight: isIOS ? 'normal' : 'bold',
            marginTop: '36px',
            marginBottom: '24px'
          }}>Create your account {isIOS && 'here'} | <Link href='/login'>Log in</Link></div>

          <form className={css(styles.loginForm)} onSubmit={async e => {
            e.preventDefault()
            try {
              const authCredential = await authConnection.createUserWithEmailAndPassword(newUser.email, newUser.password)
              await authCredential.user.sendEmailVerification()
              window.location = this.props.loginRedirectPath
            } catch (err) {
              console.error(err)
              this.setState({
                errors: true,
                errorMessage: err.code === 'auth/argument-error'
                  ? 'Error: You must provide an email and password.'
                  : err.code === 'auth/invalid-email' ? 'Error: You must enter a valid email.'
                    : String(err)
              })
            }
          }}>
            <TextInput autofocus label='Email' onChange={e => newUser.email = e.target.value} />
            <TextInput label='Password' onChange={e => newUser.password = e.target.value} />

            {this.state.errors ? <UiLabel highlight>{this.state.errorMessage}</UiLabel> : null}

            <Button type='submit' primary block>Sign up for Week Notes</Button>
          </form>

          <div className={css(styles.socialauth)}>
            <div id='firebaseui-auth-container'></div>
          </div>

        </div>

        {isMedia('large', () => this.forceUpdate()) && <div className={css(styles.copy)}>
          <GetStarted />
        </div>}

      </div>

      {this.props.isReady && !isMedia('large', () => this.forceUpdate()) && <div style={{
        margin: '40px 0',
        padding: '40px 24px',
        backgroundColor: colors.white,
        zIndex: 5
      }}>
        <GetStarted onlyDetails />
      </div>}

    </PublicContainer>
  }
}

export default Register

const styles = StyleSheet.create({
  topBar: {
    flex: '0 1 auto',
    zIndex: 3,
    position: 'relative',
  },
  notReadyContainer: {
    display: 'none'
  },
  outerContainer: {
    backgroundColor: colors.offWhite,
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'stretch'
  },
  container: {
    flex: '1 0 auto',
    maxWidth: '1024px',
    margin: '0 auto',
    padding: '0 16px',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '80px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [breakpoints.small]: {
      paddingTop: '40px',
      flexDirection: 'column',
      margin: '0 auto'
    },
    ':after': {
      content: '""',
      position: 'absolute',
      zIndex: 2,
      top: 0, left: 0, bottom: 0,
      width: '100%',
      opacity: 0.3,
      backgroundImage: 'url(/bulb-side.jpg)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      transform: 'scaleX(-1)',
      filter: 'brightness(80%)'
    }
  },
  loginFormContainer: {
    zIndex: 3,
    position: 'relative',
    borderRadius: '3px',
    maxWidth: '310px',
    marginRight: '48px',
    marginTop: '16px',
    backgroundColor: colors.white,
    boxShadow: '0 12px 20px -12px rgba(0,0,0,0.3)',
    [breakpoints.small]: {
      margin: '0 auto',
      paddingTop: '4px'
    },
  },
  loginForm: {
    borderBottom: '1px solid ' + colors.greyLight,
    paddingBottom: '24px',
    margin: '0 44px 24px',
    '@media (max-width: 990px)': {
      margin: '0 20px 24px'
    },
  },
  socialauth: {
    position: 'relative',
    ':after': {
      content: '"or"',
      position: 'absolute',
      backgroundColor: colors.white,
      top: '-33px',
      left: '143px',
      fontSize: '12px',
      display: 'block',
      padding: '0 8px',
      zIndex: 99999
    }
  },
  copy: {
    zIndex: 3,
    position: 'relative',
    maxWidth: '600px'
  },
  footer: {
    zIndex: 3,
    position: 'relative',
    flex: '0 1 auto',
    textAlign: 'center',
    height: '70px',
    lineHeight: '70px',
    borderTop: '3px solid ' + colors.greyDim,
    backgroundColor: colors.greyLight
  }
})
