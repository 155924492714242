import uuidv4 from 'uuid/v4'
import isNumber from 'lodash.isnumber'
import { datestampToReadableDate, specificMonthstamp, isTheSameDay, currentTimestampForDatestamp, currentTimestamp, timestampToDatestamp, dayOfWeek, datestampToWeekstamp } from '../components/chunks/DateFormat';

export default class NoteStore {
  id
  title
  body
  topics
  timestamp
  color
  isComplete
  dayId
  created_at
  changeHandlers = []
  stashedForReset = {}

  constructor (attrs, {exists=true}) {
    const {id='', userId, title='', body='', datestamp, timestamp, topics=[], color=null, isComplete=false, created_at} = attrs

    if (!datestamp) {
      throw new Error('no datestamp provided to new note')
    }

    if (!isNumber(datestamp)) {
      throw new Error('datestamp should be a number')
    }

    if (timestamp && (timestamp && !isTheSameDay(datestamp, timestamp))) {
      throw new Error(`note ${this.id} has unexpected weirdness happens if the note timestamp (${timestamp}) and datestamp (${datestamp}) are different days`)
    }

    this.id = id ? id : uuidv4(process.env.REACT_APP_UUID_NAMESPACE)
    this.userId = userId
    this.title = title
    this.body = body
    this.topics = topics
    this.setTimestamp(
      timestamp
        ? timestamp
        : currentTimestampForDatestamp(datestamp),
      datestamp
    )
    this.color = color
    this.isComplete = isComplete
    this.created_at = created_at || currentTimestamp()
    this.exists = exists

    this.stashedForReset = {...attrs}
  }

  reset () {
    const stashed = !this.exists
      ? new NoteStore(this.stashedForReset, {exists: false})
      : this.stashedForReset

    for (const key in stashed) {
      this[key] = stashed[key]
    }

    this.callChangeHandlers()
  }

  onChange (cb) {
    const newLength = this.changeHandlers.push(cb)

    return () => this.changeHandlers.splice(newLength - 1, 1)
  }

  callChangeHandlers () {
    this.changeHandlers.forEach(cb => cb(this))
  }

  removeTopic (topic) {
    this.topics.splice(this.topics.indexOf(topic), 1)
  }

  snapshot () {
    return {
      id: this.id,
      userId: this.userId,
      dayId: this.dayId,
      title: this.title,
      body: this.body,
      topics: this.topics,
      timestamp: this.timestamp,
      datestamp: this.datestamp,
      monthstamp: this.monthstamp,
      created_at: this.created_at,
      color: this.color,
      isComplete: this.isComplete,
      readableDate: this.readableDate,
      dayOfWeek: this.dayOfWeek
    }
  }

  setTimestamp (timestamp, datestamp) {
    this.timestamp = timestamp
    this.readableDate = datestampToReadableDate(timestamp)
    this.monthstamp = specificMonthstamp(timestamp)
    this.datestamp = datestamp || timestampToDatestamp(timestamp)
    this.dayOfWeek = dayOfWeek(timestamp)
    this.weekstamp = datestampToWeekstamp(timestamp)
  }
}
