import React from 'react'
import { ContentPage } from './content'
import { PageTitle, BodyText } from '../text'
import authConnection from '../../store/authConnection'
import TextInput from '../controls/TextInput'
import Row from '../chunks/Row'
import Button from '../controls/Button'

class LostPassword extends React.Component {
  state = {
    hasError: false,
    errorMessage: '',
    email: '',
    isSending: false,
    wasSuccessfullySent: false
  }

  render () {
    return <ContentPage>
    {this.state.wasSuccessfullySent
      ? <div style={{padding: '20px'}}>
        <PageTitle center>Ok, sent. Check your email for a message from Week Notes.</PageTitle>
      </div>
      : <div>
        <PageTitle isLoading={this.state.isSending}>Lost password fixing thingy</PageTitle>
        {this.state.hasError && <Row error>{this.state.errorMessage}</Row>}
        <BodyText spaced>Send yourself a link to reset your password.</BodyText>

        <form onSubmit={async e => {
          e.preventDefault()


          try {
            this.setState({isSending: true})
            await authConnection.sendPasswordResetEmail(this.state.email, {
              url: window.location.protocol + '//' + window.location.host + '/login'
            })
            this.setState({wasSuccessfullySent: true})
          } catch (err) {
            if (err.code === 'auth/invalid-email' || err.code === 'auth/user-not-found') {
              this.setState({
                hasError: true,
                errorMessage: 'Incorrect email address'
              })
            }
          }

          this.setState({isSending: false})
        }}>
          <TextInput
            type='email'
            label='Email'
            isRequired
            onChange={e => this.setState({email: e.target.value})}
          />
          <Button>Send</Button>
        </form>
      </div>
    }

  </ContentPage>
  }
}

export default LostPassword
