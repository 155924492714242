import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import {Heading, BodyText, UiLabel} from '../text'
import DateFormat, { dayOfWeek, isToday } from '../chunks/DateFormat'
import colors from '../colors'

class DaySummary extends React.PureComponent {
  static propTypes = {
    datestamp: PropTypes.number.isRequired,
    summaryTitle: PropTypes.string,
    summaryBody: PropTypes.string,
    onClick: PropTypes.func,
    isActive: PropTypes.bool,
    notesNotSummarizedCount: PropTypes.number
  }

  static defaultProps = {
    isActive: false
  }

  render () {
    return <div onClick={() => this.props.onClick(this.props.datestamp)} className={css(
      styles.dayContainer,
      this.props.onClick && styles.hasOnClick,
      this.props.isActive && styles.active
    )}>
      <div className={css(styles.date)}>
        <UiLabel highlight={isToday(this.props.datestamp)}>{dayOfWeek(this.props.datestamp)}</UiLabel>
        <UiLabel>
          <DateFormat format='day' datestamp={this.props.datestamp} />
        </UiLabel>
      </div>
      {this.props.summaryTitle && <Heading small>{this.props.summaryTitle}</Heading>}
      {this.props.summaryBody && <BodyText small>{this.props.summaryBody}</BodyText>}
      {this.props.notesNotSummarizedCount > 0 && <UiLabel><div>
        <br/>
        {this.props.notesNotSummarizedCount} more&hellip;
      </div></UiLabel>}
    </div>
  }
}

const styles = StyleSheet.create({
  dayContainer: {
    borderBottom: '1px solid #c8c8c8',
    padding: '16px 12px',
    minHeight: '70px',
    transition: 'background-color 200ms'
  },
  active: {
    backgroundColor: colors.greenLight,
    ':hover': {
      cursor: 'default',
      backgroundColor: colors.pinkLight,
    }
  },
  hasOnClick: {
    cursor: 'pointer',
    ':hover': {
      backgroundColor: colors.pinkUltraLight
    }
  },
  date: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px'
  }
})

export default DaySummary
