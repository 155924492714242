import React from 'react'
import PropTypes from 'prop-types'
import Button from '../controls/Button'
import { StyleSheet, css } from 'aphrodite'
import colors from '../colors'
import layers from '../layers';

class BottomBar extends React.PureComponent {
  static propTypes = {
    active: PropTypes.string,
    jumpToPlanner: PropTypes.func.isRequired,
    jumpToToday: PropTypes.func.isRequired,
    jumpToScratchPad: PropTypes.func.isRequired
  }

  render () {
    return <div className={css(styles.container)}>
      <Button strong block key='dashboard-button' small onClick={this.props.jumpToPlanner}>Day Planner</Button>
      <Button strong block key='today-button' small onClick={this.props.jumpToToday}>Today</Button>
      <Button strong block key='scratchpad-button' small onClick={this.props.jumpToScratchPad}>Scratch Pad</Button>
    </div>
  }
}

const styles = StyleSheet.create({
  container: {
    zIndex: layers.floatingUI,
    borderTop: '1px solid ' + colors.greyDim,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    position: 'fixed',
    background: colors.offWhite,
    width: '100%',
    height: '44px',
    lineHeight: '40px',
    bottom: 0
  }
})

export default BottomBar
