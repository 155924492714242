import React from 'react'
import PropTypes from 'prop-types'
import DateFormat, { currentDatestamp, tomorrowDatestamp, currentWeekstamp, weekstampToReadableWeek, datestampToReadableDate, weekstampToDatestamp, weekstampToMoment, nextWeekstamp } from '../chunks/DateFormat'
import colors from '../colors'
import dbConnection from '../../store/dbConnection'
import { currentUser } from '../../store/authConnection'
import { UiLabel } from '../text'
import Row from '../chunks/Row'
import Note from '../chunks/Note'
import Link from '../controls/Link'
import { css, StyleSheet } from 'aphrodite'
import breakpoints, { isMedia } from '../breakpoints'
import BottomBar from '../chunks/BottomBar'
import BrowserWithDetail from '../layout/BrowserWithDetail';
import WeeksList from '../controls/WeeksList';

class WeekSummary extends React.PureComponent {
  static propTypes = {
    jumpToDay: PropTypes.func.isRequired,
    jumpToTopic: PropTypes.func.isRequired,
    jumpToScratchPad: PropTypes.func.isRequired,
    weekstamp: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired
  }

  daysAdded = {}
  notes = []

  async componentDidMount () {
  	this.notesListener()
  }

  notesListener () {
  	const minimumDatestamp = weekstampToMoment(currentWeekstamp()).startOf('W').startOf('d').valueOf()
  	const maximumDatestamp = weekstampToMoment(currentWeekstamp()).endOf('W').startOf('d').valueOf()

  	const notesQuery = dbConnection.collection('notes')
  		.where('userId', '==', currentUser().uid)
  		.where('datestamp', '>=', minimumDatestamp)
  		.where('datestamp', '<=', maximumDatestamp)
  		.orderBy('datestamp', 'desc')

  	this.unsub && this.unsub()
  	this.unsub = notesQuery.onSnapshot(noteDocs => {
  		this.notes = noteDocs.docs.map(d => d.data())
  		this.forceUpdate()
  	})
  }

  componentWillUnmount () {
  	this.unsub()
  }

  componentWillUpdate () {
    this.daysAdded = {}
    return true
  }

  ensureClosedDrawer () {
    this.drawer.close()
  }

  addDay (dayName) {
    const hasAlready = this.hasDay(dayName)
    this.daysAdded[dayName] = true

    return !hasAlready // returns true/false whether the day was added
  }

  hasDay (dayName) {
    return this.daysAdded[dayName]
  }

  currentWeekNotes () {
  	return this.notes
  }

  todayNotes () {
  	return this.notes.filter(n => n.datestamp === currentDatestamp())
  }

  tomorrowNotes () {
  	return this.notes.filter(n => n.datestamp === tomorrowDatestamp())
  }

  render () {
    return <div>
      <BrowserWithDetail
        title={<DateFormat format='week' datestamp={weekstampToDatestamp(currentWeekstamp())} />}
        ref={d => this.drawer = d}
        browser={<WeeksList
        	initialSelectedDatestamp={weekstampToDatestamp(currentWeekstamp())}
        	onClickDay={datestamp => {
        		this.ensureClosedDrawer()
        		this.props.onClickDay(datestamp)
        	}}
        	onChangeWeek={this.props.onChangeWeek}
      	/>}
        bottomBar={isMedia('small', () => this.forceUpdate()) ? <BottomBar
          jumpToPlanner={() => this.props.jumpToPlanner()}
          jumpToToday={() => this.props.jumpToToday()}
          jumpToScratchPad={() => this.props.jumpToScratchPad()}
        /> : null}
        detail={<div className={css(weekSummaryStyles.container)}>
          <div className={css(weekSummaryStyles.thisWeek)}>
          	<Row
              length={this.currentWeekNotes().length}
              title={weekstampToReadableWeek(currentWeekstamp())}>
              {this.currentWeekNotes().map(note => <div key={note.id}>
                {this.addDay(note.dayOfWeek) && <UiLabel><DateFormat format='dayOfWeek' datestamp={note.timestamp} /></UiLabel>}
                <Note
                  onClickNote={() => this.props.jumpToNote(note)}
                  onClickTopic={slug => this.props.jumpToTopic(slug)}
                  condensed
                  title={note.title}
                  note={note.body}
                  topics={note.topics}
                  timestamp={note.timestamp}
                />
              </div>)}
            </Row>
          </div>

          <div className={css(weekSummaryStyles.recentNotes)}>
            <Row
              spaced={isMedia('handheld', () => this.forceUpdate())}
              title={<Link dark to='/today'>Today</Link>}
              length={this.todayNotes(this.props.weeks).length}
              afterCollapsedContent={<div>
                <Link heavy small to={`/day-planner/${currentWeekstamp()}/${datestampToReadableDate(currentDatestamp())}/add-note`}>+ Write</Link><br />
              </div>}
            >
              {this.todayNotes().map(note => <Note
                primary
                key={note.id}
                condensed
                title={note.title}
                note={note.body}
                topics={note.topics}
                timestamp={note.timestamp}
                onClickTopic={slug => this.props.jumpToTopic(slug)}
              />)}
            </Row>



            <Row
              spaced
              length={this.tomorrowNotes().length}
              title={<Link dark to='/tomorrow'>Tomorrow</Link>}
              afterCollapsedContent={<div>
                <Link heavy small to={`/day-planner/${currentWeekstamp()}/${datestampToReadableDate(tomorrowDatestamp())}/add-note`}>+ Write</Link><br />
              </div>}
            >
              {this.tomorrowNotes().map(note =>
                <Note
                  key={note.id}
                  condensed
                  title={note.title}
                  note={note.body}
                  topics={note.topics}
                  timestamp={note.timestamp}
                  onClickTopic={slug => this.props.jumpToTopic(slug)}
                />
              )}
            </Row>


          </div>
        </div>}
      />
    </div>
  }
}

export const weekSummaryStyles = StyleSheet.create({
  container: {
    position: 'relative',
    maxWidth: '900px',
    minHeight: '100%',
    minWidth: '100%',
    margin: '0 0 44px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '64px 24px 16px',

    [breakpoints.large]: {
      backgroundColor: colors.offWhite,
    },

    [breakpoints.handheld]: {
      display: 'block',
      float: 'none',
      clear: 'both',
      maxWidth: '400px'
    }
  },
  thisWeek: {
    flex: 0.4,
  },
  recentNotes: {
    flex: 0.4
  }
})

export default WeekSummary
