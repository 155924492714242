import React from 'react'
import PropTypes from 'prop-types'
import { PageTitle } from '../text'
import { StyleSheet, css } from 'aphrodite';

class LoadingMessage extends React.PureComponent {
  static propTypes = {
    message: PropTypes.string.isRequired
  }

  render () {
    return <div className={css(styles.container)}>
      <PageTitle isLoading>{this.props.message}</PageTitle>
    </div>
  }
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginTop: '48px',
    textAlign: 'center'
  }
})

export default LoadingMessage
